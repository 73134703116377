import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SiteLocationRichText from '../SiteLocationRichText/SiteLocationRichText';
import {
  Text,
  TextField,
  RichTextField,
  ImageField,
  Image,
  useSitecoreContext,
  // NextImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { UIButton } from '../shared/UIButton.styles';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useDispatch } from 'react-redux';
import { siteSelection } from '../../features/siteSelectionSlice';
import { StCardText, StCardTitleText } from '../../components/SitesSelection/SiteSelection.styles';
// import { StNextImage } from 'components/shared/NextImage.styles';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from './PhoneIcon';
//import StartIconHigh from '../../../public/star-icon-high.svg';
//import { StNextIcon } from 'components/shared/NextImage.styles';

export interface ISiteInfo {
  siteCode?: string | number;
  siteName?: string;
  siteAddress?: string;
  siteCity?: string;
  siteState?: string | null;
  siteCountry?: string;
  siteZipCode?: string;
  distance?: string | number;
  isSelected?: string;
  contactPhoneNumber?: string;
  latitude: number;
  longitude: number;
  [key: string]: any; // Index signature for additional properties
  isHybridSite: boolean;
}
interface ISiteInfoCardProps {
  siteInfo: ISiteInfo;
  buttonText: TextField;
  siteAddressFormat: RichTextField;
  isShowSelectedColor?: boolean;
  cardButtonAriaLabel?: string;
  phoneIcon?: ImageField;
  mapIcon?: ImageField;
  virtualIcon?: ImageField;
  virtualMapIcon?: ImageField;
  virutalSiteText?: TextField;
  isCompactCard?: boolean;
  premiereSiteIcon?: ImageField;
}

const SiteInfoCard: React.FC<any> = (props: ISiteInfoCardProps) => {
  const isShowSelectedColor = props.isShowSelectedColor ?? true;
  const theme = useTheme();
  const siteName = props.siteInfo.siteName || '';
  const phoneNumber = props.siteInfo.contactPhoneNumber || '';
  const siteDistance = props.siteInfo.distance ?? '';
  const isHybrid = props.siteInfo.isHybridSite || false;
  const isCompactCard = props?.isCompactCard || false;
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const distanceMetricText = siteSettings?.distanceMetric?.fields?.label?.value || 'mi';
  const selectedPlace = useSelector((state: RootState) => state.selectedSite);
  const dispatch = useDispatch();

  //TODO: this needs to compare with id, once we get from backend to highlight card
  const isSelected = selectedPlace?.siteInfo?.siteCode === props?.siteInfo?.siteCode || false;
  const buttonText = props.buttonText;
  const siteAddressFormat = props.siteAddressFormat;
  const borderColor = isSelected ? theme.palette.primary.main : theme.palette.neutral4.main;

  const onSiteSelect = (isConfirm = false) => {
    dispatch(
      siteSelection({
        siteInfo: props?.siteInfo as any,
        selectionType: isConfirm ? 'Confirm' : 'Partial',
        shouldInfoWindowVisible: true,
      })
    );
  };

  return (
    <>
      <Card
        tabIndex={0}
        sx={{
          borderRadius: '8px',
          border: isShowSelectedColor ? `2px solid ${borderColor}` : '',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        }}
        onClick={() => onSiteSelect()}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            onSiteSelect();
          }
        }}
        id="siteInfoCard"
      >
        <CardContent
          sx={{
            padding: '16px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <StCardTitleText gutterBottom data-testid="siteTitle">
              {siteName}
            </StCardTitleText>
            {props?.siteInfo?.tier === 1 && props?.siteInfo?.isSitePremiere && (
              <Image field={props?.premiereSiteIcon} />
            )}
          </div>
          <Divider />
          <Box
            sx={{
              '& p': {
                marginBlock: (theme) => theme.spacing(1),
                '& strong': {
                  fontWeight: 400,
                },
              },
            }}
          >
            <SiteLocationRichText
              style={{
                margin: 0,
                fontFamily: 'Proxima Nova',
                fontSize: '14px',
                fontWeight: 400,
                color: theme.palette.black.main,
                minHeight: '35px',
              }}
              siteAddressFormat={siteAddressFormat}
              siteInfo={props.siteInfo}
            />
          </Box>
          {!isCompactCard && (
            <Box display="flex" columnGap="2rem" data-testid="phone-and-distance">
              {phoneNumber && phoneNumber !== '' && (
                <Box display="flex" columnGap="5px" sx={{ alignItems: 'center' }}>
                  {/* <StNextImage field={props.phoneIcon} height="17px" width="17px" /> */}
                  <PhoneIcon
                    sx={{
                      color: (theme) => theme.palette.themeColor.main,
                      height: '17px',
                      width: '17px',
                    }}
                  />
                  <StCardText>{phoneNumber}</StCardText>
                </Box>
              )}
              {(siteDistance === null || siteDistance === '' || siteDistance === undefined) &&
              isHybrid ? (
                <Box display="flex" columnGap="5px" sx={{ alignItems: 'center' }}>
                  <Image field={props?.virtualIcon} />
                  <StCardText>{props.virutalSiteText?.value}</StCardText>
                </Box>
              ) : (
                <Box display="flex" columnGap="5px" sx={{ alignItems: 'center' }}>
                  {/* <StNextImage field={props.mapIcon} height="17px" width="17px" /> */}
                  <PlaceIcon
                    sx={{
                      color: (theme) => theme.palette.themeColor.main,
                      height: '17px',
                      width: '17px',
                      //alignSelf: 'baseline',
                    }}
                  />
                  <StCardText>
                    {siteDistance} {distanceMetricText}
                  </StCardText>
                </Box>
              )}
            </Box>
          )}
          <UIButton
            className="btn"
            aria-label={props.cardButtonAriaLabel}
            tabIndex={0}
            variant={isSelected ? 'contained' : 'outlined'}
            sx={{
              mt: '1rem',
              p: '4px',
              width: isCompactCard ? '20%' : '100%',
              minWidth: isCompactCard ? '120px' : '90%',
              left: isCompactCard ? '50%' : '0',
              transform: isCompactCard ? 'translateX(-50%)' : 'none',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onSiteSelect(true);
            }}
          >
            <Text field={buttonText}></Text>
          </UIButton>
        </CardContent>
      </Card>
    </>
  );
};

export default SiteInfoCard;
