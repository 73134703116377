import { useEffect, useState } from 'react';
import { Field, RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { JSSLink } from '../../shared/Links/JSSLink.styles';
import { ManageCookieProps, CookieBannerProps } from '../Cookie.types';
import { acceptAll, setOptinCookies, rejectAll } from '../../../lib/utils/cookieHelpers';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { UIButton } from '../../../components/shared/UIButton.styles';
import { setAcceptCookies } from '../../../lib/utils/cookieHelpers';
import { hideCookieBanner, setIsShowCookieBanner } from '../../../features/cookieBannerSlice';
import { trackEvent } from '../../../lib/utils/gtmService';
import { getCookie, getEnrollmentId } from '../../../lib/utils/enrollmentUtil';
import { getCookie as getNextCookie } from 'cookies-next';
import { CR_SITE } from '../../../lib/constant';

interface CookieBannerParams {
  acceptGACookie: () => void;
  gtmId: string;
}

const CookieBanner = (props: CookieBannerParams): JSX.Element => {
  const { acceptGACookie, gtmId } = props;
  const [guestId, setGuestId] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const { sitecoreContext }: any = useSitecoreContext();
  const siteName = sitecoreContext?.site?.name;

  const globalCookieBanner = sitecoreContext?.globalData?.globalCookieBanner as CookieBannerProps;

  const globalCookiePreference = sitecoreContext?.globalData
    ?.globalCookiePreference as ManageCookieProps;

  const cookieBanner = (sitecoreContext?.cookieBanner as CookieBannerProps) || globalCookieBanner;
  const cookiePreference =
    (sitecoreContext?.cookiePreference as ManageCookieProps) || globalCookiePreference;
  const isHomePage = (sitecoreContext?.route?.fields?.isHomePage as any)?.value || false;
  const cdpState = useSelector((state: RootState) => state.cdpData);

  const siteSettings: any = sitecoreContext?.siteSettings;
  const isCustomCookieEnabled = siteSettings?.isCustomCookieEnabled?.value;

  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);

  const rejectButton: Field<string> = {
    value: cookieBanner?.rejectLabel?.value || 'Reject',
  };
  const acceptButton: Field<string> = {
    value: cookieBanner?.acceptLabel?.value || 'Accept',
  };

  const cookiePreferencesLinkText =
    cookieBanner?.cookiePreferencesLinkText?.value || 'Manage Cookie';
  const managePreferenceButton = {
    fields: {
      buttonVariant: {
        value: '',
      },
      link: {
        value: {
          href: '#',
          title: cookiePreferencesLinkText,
          text: cookiePreferencesLinkText,
        },
      },
    },
  };

  const dispatch = useDispatch();

  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const potentialParticipant_ID =
    potentialParticipantDetailsFromStore?.potentialParticipant_ID || '';
  const cookieBannerState = useSelector((state: RootState) => state.cookieBanner);
  const isShowCookieBanner = cookieBannerState?.isShowCookieBanner || false;

  const cookieExpiryDateInDays = cookieBanner?.cookieExpiryDateInDays?.value || 1;

  const disableAcceptButton = !potentialParticipant_ID && !guestId;

  // const guestId = getNextCookie('bx_guest_ref') || '';

  const setGACookies = (): void => {
    // setOptinCookies(true, true);
    trackEvent(
      {
        event: 'Record ID generated',
        recordId: getEnrollmentId(),
      },
      siteName === CR_SITE ? true : false
    );
    trackEvent(
      {
        event: 'Journey Type identified',
        journeyType: journeyType,
      },
      siteName === CR_SITE ? true : false
    );
  };

  const handleAcceptChange = async () => {
    const cookieCategories = cookiePreference?.cookieCategory;
    const categoryNames: string[] = [];
    if (Array.isArray(cookieCategories)) {
      cookieCategories.forEach((item) => {
        const categoryName = item?.fields?.name?.value || '';
        categoryName && categoryNames.push(categoryName);
      });
    }
    const guestType = cdpState?.guestType || 'visitor';
    await acceptAll(
      potentialParticipant_ID || guestId,
      categoryNames,
      guestType,
      cookieExpiryDateInDays
    );
    dispatch(hideCookieBanner());
    if (gtmId) await acceptGACookie();
    setOptinCookies(true, true);
    setAcceptCookies(true, cookieExpiryDateInDays);
    setGACookies();
  };

  const handleRejectChange = async () => {
    const cookieCategories = cookiePreference?.cookieCategory;
    const categoryNames: string[] = [];
    if (Array.isArray(cookieCategories)) {
      cookieCategories.forEach((item) => {
        const categoryName = item?.fields?.name?.value || '';
        categoryName && categoryNames.push(categoryName);
      });
    }
    const guestType = cdpState?.guestType || 'visitor';
    await rejectAll(
      potentialParticipant_ID || guestId,
      categoryNames,
      guestType,
      cookieExpiryDateInDays
    );
    dispatch(hideCookieBanner());
    setAcceptCookies(false, cookieExpiryDateInDays);
    setOptinCookies(false, false);
  };

  useEffect(() => {
    dispatch(setIsShowCookieBanner());
    if (getCookie('AcceptCookie') === 'true' && isHomePage) {
      setGACookies();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const gId = getNextCookie('bx_guest_ref') as string;
      if (gId && guestId !== gId) {
        setGuestId(gId);
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [guestId]);

  if (isEditorActive()) {
    return <></>;
  }
  const consent = (
    <Paper sx={{ m: 1 }} elevation={4}>
      <Box
        component="animate"
        sx={{
          position: 'fixed',
          paddingTop: isMobile ? 3 : 4,
          paddingBottom: isMobile ? 3 : 4,
          paddingLeft: 5,
          paddingRight: 5,
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: theme.palette?.backgroundType4?.main,
          zIndex: 100,
        }}
      >
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              data-testid="title"
              sx={{
                display: 'flex',
                color: theme.palette.white.main,
                marginBottom: '8px',
                lineHeight: '1.5rem',
                fontWeight: 700,
              }}
            >
              <Text field={cookieBanner?.heading} />
            </Typography>
            <Box sx={{ color: theme.palette.white.main }}>
              <RichText data-testid="content" field={cookieBanner?.description} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            justifyContent="space-between"
            alignItems={isMobile ? 'center' : 'baseline'}
            sx={{ paddingTop: '8px !important' }}
          >
            <JSSLink
              className={'manage-cookies link--white manage-cookies-btn btn'}
              data-testid="manage-cookies"
              field={managePreferenceButton?.fields?.link}
            />
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={2}
              sx={{ width: isMobile ? '100%' : 'auto', marginBottom: isMobile ? '1rem' : '' }}
            >
              <UIButton
                className="btn"
                sx={{ paddingBlock: '4px' }}
                variant="outlined"
                disabled={disableAcceptButton}
                onClick={() => {
                  handleRejectChange();
                }}
              >
                <Text field={rejectButton} />
              </UIButton>
              <UIButton
                className="btn"
                sx={{ paddingBlock: '4px' }}
                variant="outlined"
                disabled={disableAcceptButton}
                onClick={() => {
                  handleAcceptChange();
                }}
              >
                <Text field={acceptButton} />
              </UIButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
  if (isCustomCookieEnabled && isShowCookieBanner && siteName !== CR_SITE) {
    return <Fade in={isShowCookieBanner}>{consent}</Fade>;
  } else {
    return <></>;
  }
};

export default CookieBanner;
