import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StChip, StFormLabel } from './AutocompleteMultiSelect.style';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import { AutocompleteMultiSelectProps } from './AutocompleteMultiSelect.types';

const AutocompleteMultiSelect = ({
  id,
  options,
  noOptionText,
  label,
  placeholder,
  value,
  onChangeHandler,
  limitTags,
  maxVisibleOptions,
  isContactInfo = false,
  sx,
}: AutocompleteMultiSelectProps): React.ReactElement => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const filterOptions = (options: any, { inputValue }: any) => {
    if (inputValue === '') {
      return options;
    }
    const filteredList = options.filter((option: any) => {
      if (
        option.category.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.description.toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    return filteredList;
  };

  const sortList = (options: any[]) => {
    return [...options]?.sort((a, b) => {
      const categoryComparison = -b?.category?.localeCompare(a?.category);
      if (categoryComparison !== 0) {
        return categoryComparison;
      }
      return a?.description?.localeCompare(b?.description);
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: isContactInfo ? 'flex-start' : 'center',
      }}
    >
      <Autocomplete
        multiple
        id={id}
        data-testid={id}
        filterOptions={filterOptions}
        options={sortList(options)}
        disableClearable={false}
        limitTags={limitTags || 3}
        value={value}
        groupBy={(option) => option.category}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        disableCloseOnSelect={true}
        clearOnBlur={true}
        onChange={(_e, newValue) => {
          onChangeHandler(newValue);
        }}
        sx={sx}
        renderTags={(tagValue, getTagProps) => {
          if (maxVisibleOptions) {
            const visibleOptions = tagValue.slice(0, maxVisibleOptions);
            const remainingCount = tagValue.length - maxVisibleOptions;
            return (
              <>
                {visibleOptions.map((option, index) => (
                  <StChip
                    {...getTagProps({ index })}
                    key={option.key}
                    label={
                      option.abbreviation && option.abbreviation !== ''
                        ? option.abbreviation
                        : option.description
                    }
                    avatar={option.icon ? <Avatar alt={option.key} src={option.icon} /> : <></>}
                  />
                ))}
                {remainingCount > 0 && <div>+{remainingCount} more</div>}
              </>
            );
          }

          return tagValue.map((option, index) => (
            <>
              <StChip
                {...getTagProps({ index })}
                key={option.key}
                label={
                  option.abbreviation && option.abbreviation !== ''
                    ? option.abbreviation
                    : option.description
                }
                avatar={option.icon ? <Avatar alt={option.key} src={option.icon} /> : <></>}
              />
            </>
          ));
        }}
        getOptionLabel={(option) => option.key}
        renderOption={(props, option, data) => {
          return (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={data.selected}
              />
              <span style={{ color: '#444444' }}>{option.description}</span>
            </li>
          );
        }}
        style={{
          width: isMobile || isContactInfo ? '100%' : '520px',
          maxWidth: isContactInfo ? '100%' : '520px',
        }}
        noOptionsText={noOptionText || ''}
        renderInput={(params) => {
          return (
            <>
              <StFormLabel htmlFor={'conditions-of-interest'}>{label}</StFormLabel>
              <TextField
                {...params}
                placeholder={value?.length < 1 ? placeholder : ''}
                variant="outlined"
                sx={{
                  '& ::placeholder': {
                    color: `${theme.palette.neutral7.main} !important`,
                    fontFamily: 'Proxima Nova',
                    fontSize: '16px',
                    lineHeight: '16px',
                    opacity: 100,
                  },
                  '& .MuiChip-label': {
                    fontWeight: 500,
                    color: (theme) => theme.palette.neutral8.main,
                  },
                  ...(isContactInfo && {
                    '& .MuiInputBase-root:first-child': {
                      padding: '3px !important',
                      paddingRight: '39px !important',
                      '& ::placeholder': {
                        color: `${theme.palette.neutral5.main} !important`,
                      },
                    },
                  }),
                }}
              />
            </>
          );
        }}
      />
    </Box>
  );
};

export default AutocompleteMultiSelect;
