import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NextImage, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  StTypographyHeadingText,
  StTypographyTitle,
  StArticleWrapContainer,
  StContainer,
} from './ArticlePage.style';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

const ArticlePage: React.FC<any> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const content = props?.fields?.content;
  const headingText = props?.fields?.headingText;
  const articlePublishDate = props?.fields?.articlePublishDate;
  const articleTitle = props?.fields?.articleTitle;
  const articleConditions = props?.fields?.articleConditions;
  const articleImage = props?.fields?.articleImage;
  const publishedDateText = props?.fields?.publishedDateText;
  const date = new Date(articlePublishDate?.value);

  const formattedDate = date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const imageStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'normal',
    justifyContent: 'space-between',
    width: isMobile ? '100%' : '50%',
    '& > span': {
      ...(isMobile && { minHeight: '274px' }),
    },
  };

  const textStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: isMobile ? '0' : '20px',
    marginRight: isMobile ? '0' : '20px',
    ...(isMobile && { padding: '7%' }), // fixed syntax error
    width: isMobile ? '100%' : '50%',
  };

  return (
    <StContainer container>
      <StArticleWrapContainer item container direction={'column'}>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          sx={{
            backgroundColor: theme.palette.themeColor.main,
            borderTopRightRadius: isTablet ? undefined : '16px',
            borderTopLeftRadius: isTablet ? undefined : '16px',
          }}
        >
          <Grid sx={imageStyle}>
            <NextImage
              field={articleImage}
              priority={true}
              style={{
                zIndex: 0,
                borderTopLeftRadius: isTablet ? undefined : '16px',
                width: '100%', // Make it responsive
                height: isMobile ? '350px' : '500px', // Set dynamic height based on screen size
                objectFit: 'cover', // Ensures the image is not distorted
              }}
              // width={700}
              // height={isMobile ? 350 : 500}
            />
          </Grid>
          <Grid sx={textStyle}>
            <Typography sx={{ color: theme.palette.white.main }}>
              {articleConditions?.displayName}
            </Typography>
            <StTypographyTitle variant="h1">{articleTitle?.value}</StTypographyTitle>
            <Placeholder name="jss-share" rendering={props.rendering} />
          </Grid>
        </Stack>
        <Grid className="content" sx={{ backgroundColor: theme.palette.white.main }}>
          <Grid sx={{ margin: '1rem 2rem 2rem' }}>
            <StTypographyHeadingText variant="h1" sx={{ marginBottom: '2rem' }}>
              {headingText?.value}
            </StTypographyHeadingText>
            <Grid
              item
              data-testid="content"
              sx={{ color: theme.palette.neutral9.main, fontSize: '1.125rem', fontWeight: 400 }}
            >
              <RichText field={content} />
            </Grid>

            <Typography
              sx={{ color: theme.palette.neutral9.main, fontStyle: 'italic', marginTop: '1rem' }}
            >
              {publishedDateText?.value || 'Published date:'} {formattedDate}
            </Typography>
          </Grid>
        </Grid>
      </StArticleWrapContainer>
    </StContainer>
  );
};

export default ArticlePage;
