import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SiteInfoCard from '../SiteInfoCard/SiteInfoCard';
import { TextField, RichTextField, ImageField, Image } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect } from 'react';
import { CDP_EVENTS } from '../../constants';
import * as cdpService from '../../services/CdpService';
import { UILink } from '../../components/shared/Links/UILink.styles';

interface ISiteListProps {
  siteDetails: any;
  buttonText: TextField;
  siteAddressFormat: RichTextField;
  cardButtonAriaLabel?: string;
  mapIcon: ImageField;
  phoneIcon: ImageField;
  isError: boolean;
  virtualIcon: ImageField;
  virtualMapIcon: ImageField;
  virutalSiteText: TextField;
  shouldShowViewMore?: string;
  viewAllSites?: () => void;
  groupTwoSites?: any;
  toggleActivePanel?: any;
  activePannel?: any;
  viewMoreText: string;
  viewLessText: string;
  bestMatchsitesText: string;
  premiereSiteIcon: ImageField;
  bestMatchSitesIcon: ImageField;
  viewGroupOneSites?: () => void;
  showViewButtonFirstScreen?: boolean;
  showViewButtonSecondScreen?: boolean;
}

const SitesList: React.FC<ISiteListProps> = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.between(600, 959));
  const viewButtonColor = theme.palette.primary.dark;

  const noSitesAvailableCDPEvent = () => {
    const extensionData = {};
    cdpService.event(CDP_EVENTS.SITE_SELECTION_NO_SITE_AVAILABLE, extensionData);
  };

  useEffect(() => {
    if (props?.siteDetails?.length === 0) {
      noSitesAvailableCDPEvent();
    }
  }, [props]);

  const calculateDynamicHeight = () => {
    if (!isDesktop) {
      const cardRows = Math.round(props?.siteDetails?.length / 2);
      const PADDING = 64;
      return `${cardRows * 250 + PADDING}px`;
    } else {
      return '600px';
    }
  };

  return (
    <Box
      id="sites-list"
      sx={{
        padding: isDesktop ? '13px 5px 13px 12px' : '0px 16px 0px 32px',
      }}
    >
      {!isDesktop &&
        props?.groupTwoSites &&
        props?.groupTwoSites?.length > 0 &&
        props?.activePannel === 'groupTwo' && (
          <UILink
            title={props?.bestMatchsitesText}
            className="btn"
            onClick={props?.toggleActivePanel}
            sx={{
              marginLeft: '2px',
              '&: hover': {
                borderBottom: 'none !important',
              },
            }}
          >
            <Image field={props?.bestMatchSitesIcon} />
            <Typography component={'span'} sx={{ padding: 0, margin: '0 0 0 5px' }}>
              {props?.bestMatchsitesText}
            </Typography>
          </UILink>
        )}
      {isDesktop &&
        props?.groupTwoSites &&
        props?.groupTwoSites?.length > 0 &&
        props?.activePannel === 'groupTwo' && (
          <UILink
            title={props?.bestMatchsitesText}
            className="btn"
            onClick={props?.toggleActivePanel}
            sx={{
              display: 'flex',
              lineHeight: '0',
              marginLeft: '2px',
              marginBottom: '15px',
              margin: '0 0 5px 2px',
              '&: hover': {
                borderBottom: 'none !important',
              },
            }}
          >
            <Image field={props?.bestMatchSitesIcon} />
            <Typography component={'span'} sx={{ padding: 0, margin: '0 0 0 5px' }}>
              {props?.bestMatchsitesText}
            </Typography>
          </UILink>
        )}
      <Grid
        container
        sx={{
          overflowY: 'auto',
          height: calculateDynamicHeight(),
          maxHeight: '524px',
          padding: isDesktop ? '0 7px 0 0' : '0 16px 0 0',
        }}
        justifyContent="flex-start"
        spacing={isDesktop ? 0 : 1.2}
        rowGap={isDesktop ? 2 : 2}
      >
        {props.isError && <p>No sites available</p>}
        {!props.isError &&
          Array.isArray(props.siteDetails) &&
          props.siteDetails.length > 0 &&
          props.siteDetails.map((siteItem, index) => {
            return (
              <Grid
                item
                key={index}
                sx={{ width: '100%' }}
                xs={isDesktop || isMobile ? 12 : 6}
                id={
                  index === 0 && props.activePannel === 'groupTwo'
                    ? 'first-site-groupTwo'
                    : index === 0 && props.activePannel === 'groupOne'
                    ? 'first-site-groupOne'
                    : undefined
                }
              >
                <SiteInfoCard
                  siteInfo={siteItem}
                  buttonText={props.buttonText}
                  siteAddressFormat={props.siteAddressFormat}
                  cardButtonAriaLabel={props.cardButtonAriaLabel}
                  mapIcon={props?.mapIcon}
                  phoneIcon={props?.phoneIcon}
                  virtualIcon={props?.virtualIcon}
                  virtualMapIcon={props?.virtualMapIcon}
                  virutalSiteText={props?.virutalSiteText}
                  premiereSiteIcon={props?.premiereSiteIcon}
                />
              </Grid>
            );
          })}
        {props?.shouldShowViewMore == 'true' &&
          props?.showViewButtonFirstScreen &&
          props?.activePannel !== 'groupTwo' && (
            <Box
              sx={{
                cursor: 'pointer',
                color: viewButtonColor,
                fontSize: '1rem',
                fontWeight: '700',
                textAlign: 'center',
                margin: '0 auto',
                marginTop: 'auto',
              }}
              onClick={props?.viewAllSites}
            >
              {!isTablet && (
                <Typography sx={{ padding: 0, margin: 0 }}>{props.viewMoreText}</Typography>
              )}
            </Box>
          )}
        {props?.shouldShowViewMore == 'true' &&
          props?.showViewButtonSecondScreen &&
          (props?.activePannel === 'groupTwo' || props?.activePannel === 'all') && (
            <Box
              sx={{
                cursor: 'pointer',
                color: viewButtonColor,
                fontSize: '1rem',
                fontWeight: '700',
                textAlign: 'center',
                margin: '0 auto',
                marginTop: 'auto',
              }}
              onClick={props?.viewAllSites}
            >
              {!isTablet && (
                <Typography sx={{ padding: 0, margin: 0 }}>{props.viewMoreText}</Typography>
              )}
            </Box>
          )}
        {props?.shouldShowViewMore == 'false' &&
          props?.shouldShowViewMore !== undefined &&
          props?.shouldShowViewMore?.trim() !== '' && (
            <Box
              sx={{
                cursor: 'pointer',
                color: viewButtonColor,
                fontSize: '1rem',
                fontWeight: '700',
                textAlign: 'center',
                margin: '0 auto',
                marginTop: 'auto',
              }}
              onClick={props?.viewGroupOneSites}
            >
              {!isTablet && (
                <Typography sx={{ padding: 0, margin: '5px !important' }}>
                  {props.viewLessText}
                </Typography>
              )}
            </Box>
          )}
      </Grid>
      {isTablet && (
        <Typography
          sx={{
            padding: 0,
            margin: 0,
            textAlign: 'center',
            cursor: 'pointer',
            color: viewButtonColor,
            fontSize: '1rem',
            fontWeight: '500',
          }}
          onClick={
            props?.shouldShowViewMore == 'true' ? props?.viewAllSites : props?.viewGroupOneSites
          }
        >
          {props?.shouldShowViewMore == 'true' &&
            props?.showViewButtonSecondScreen &&
            (props?.activePannel === 'groupTwo' || props?.activePannel === 'all') && (
              <Typography>{props.viewMoreText}</Typography>
            )}
          {props?.shouldShowViewMore == 'true' &&
            props?.showViewButtonFirstScreen &&
            props?.activePannel !== 'groupTwo' && <Typography>{props.viewMoreText}</Typography>}
          {props?.shouldShowViewMore == 'false' &&
            props?.shouldShowViewMore !== undefined &&
            props?.shouldShowViewMore?.trim() !== '' && (
              <Typography>{props.viewLessText}</Typography>
            )}
        </Typography>
      )}
    </Box>
  );
};

export default SitesList;
