import Box from '@mui/material/Box';
import { default as Checkbox, CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Field,
  NextImage,
  RichText,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { IItemWithString, ISitecoreImage } from 'lib/type/common';
import Image from 'next/image';
import React, { forwardRef, useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateRegistration } from 'src/features/registrationSlice';
import { RootState } from 'src/store/store';
import EmailAutoSugest from './EmailAutocomplete';
import notificationIcon from './notification_icon.svg';
import StRegistrationContainer, { StRegistrationWrapper } from './Registration.style';
import useScrollToElement from '../../../lib/hooks/useScrollToElement';
import axios from 'axios';
import { UIButton } from 'components/shared/UIButton.styles';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/router';
import * as cdpService from '../../../services/CdpService';
import { getCookie } from 'lib/utils/enrollmentUtil';
import { store } from 'src/store/store';
import { updateLastVisitedComponentId } from '../../../features/globalConfig';
import { hideLoader, showLoader } from '../../../features/loaderSlice';
import { resetCookies } from '../../../lib/utils/cookieHelper';

type CheckboxInputProps = ControllerRenderProps & CheckboxProps & any;

interface RegistrationProps {
  fields: {
    consentDescription: Field<string>;
    consentRequiredValidationMessage: Field<string>;
    content: Field<string>;
    ctaLink: any;
    emailDomains: IItemWithString[];
    emailRegex: any;
    emailTitle: Field<string>;
    heading: Field<string>;
    invalidInputEmailValidationMessage: Field<string>;
    isConsentRequired: Field<boolean>;
    isEmailRequired: Field<boolean>;
    requiredEmailValidationMessage: Field<string>;
    sectionIcon: ISitecoreImage;
    emailExistsMessage: Field<string>;
  };
}
const Registartion: React.FC<RegistrationProps> = (props: RegistrationProps) => {
  const {
    control,
    formState: { errors, isValid },
    getValues,
    setError,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const registration = useSelector((state: RootState) => state.registrationDetails.registration);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const { t } = useI18n();
  const router = useRouter();
  const scrollId = useSelector((state: RootState) => state.scrollId.scrollId);
  const scrollToElement = useScrollToElement();
  const emailExistsMessage = props?.fields?.emailExistsMessage;
  const { sitecoreContext } = useSitecoreContext();
  const state = store.getState();
  const isAuthenticated: boolean = state.authentication.isAuthenticated;

  const config = {
    clientKey: process.env.NEXT_PUBLIC_CDP_CLIENT_KEY || 'de665c8ff4f47bfd6e5f7313baff0442',
    targetURL:
      process.env.NEXT_PUBLIC_CDP_API_TARGET_ENDPOINT || 'https://api-engage-eu.sitecorecloud.io',
    pointOfSale: sitecoreContext?.site?.name,
    cookieDomain: '',
    cookieExpiryDays: 365,
    forceServerCookieMode: false,
    includeUTMParameters: true,
    webPersonalization: true,
    scriptUrl:
      process.env.NEXT_PUBLIC_SITECORE_ENGAGE ||
      'https://d1mj578wat5n4o.cloudfront.net/sitecore-engage-v.1.3.0.min.js',
  };

  const loadCDPScript = (data: any) => {
    //removing existing cdpScript
    const existingCdpScript: HTMLElement = document.getElementById('cdp-script') as HTMLElement;

    if (existingCdpScript) {
      document.head.removeChild(existingCdpScript);
    }

    //adding cdp script
    const script = document.createElement('script');
    script.src = config.scriptUrl; // The URL of the Sitecore Engage script
    script.async = true;
    script.id = 'cdp-script';
    resetCookies();

    document.head.appendChild(script);
    script.onload = async function () {
      if (window.Engage && typeof window.Engage.init === 'function') {
        const engage = await window.Engage.init(config);
        window.Engage.exposedFunctions = engage;
        cdpService.identityEvent(data?.email);
        dispatch(updateRegistration(getValues()));
        router.push(props?.fields?.ctaLink?.value?.href);
      }
    };
  };

  useEffect(() => {
    if (scrollId) {
      scrollToElement(scrollId);
    }
  }, [scrollId]);

  const handleRegistration = async (data: any) => {
    try {
      const guestRf = getCookie('bx_guest_ref');
      const URL = `/api/cdp-api?url=/api/contacts/ValidateCrEmailId&email=${data?.email}&guestRef=${guestRf}`;
      dispatch(showLoader());
      const response = await axios.get(URL);
      if (response?.data?.isEmailExist) {
        setError('email', { type: 'manual', message: emailExistsMessage?.value });
      } else if (response?.data?.isReloadCdpScript) {
        loadCDPScript(data);
      } else {
        cdpService.identityEvent(data?.email);
        dispatch(updateRegistration(getValues()));
        dispatch(updateLastVisitedComponentId('cr-registration'));
        router.push(props?.fields?.ctaLink?.value?.href);
      }
      dispatch(hideLoader());
    } catch (_error) {
      dispatch(hideLoader());
    }
  };

  const CheckboxInput: React.FC<CheckboxInputProps> = forwardRef(
    ({ value, ...rest }, ref: CheckboxInputProps) => {
      return (
        <Checkbox
          checked={!!value}
          {...rest}
          sx={{
            alignSelf: 'flex-start',
            padding: '0 9px',
            '.MuiCheckbox-root': {
              color: 'black',
              backgroundColor: 'black',
            },
          }}
          id="consent-checkbox"
          className="checkbox"
          value="remember"
          color="primary"
          ref={ref}
        />
      );
    }
  );
  CheckboxInput.displayName = 'CheckboxInput';

  const style = {
    padding: '5px',
    width: '212px',
    ...(!isValid && { pointerEvents: 'none', cursor: 'default', opacity: '0.6' }),
  };
  if (!props?.fields || isAuthenticated) return <></>;
  return (
    <StRegistrationContainer id="cr-registration">
      <form onSubmit={handleSubmit(handleRegistration)}>
        <StRegistrationWrapper>
          <Box alignSelf="center">
            <NextImage field={props?.fields?.sectionIcon} height={89} width={89} />
          </Box>
          <Box>
            <Typography
              align="center"
              sx={{
                color: theme.palette.white.main,
                fontSize: '30px',
                fontWeight: 600,
                lineHeight: '30px',
                marginBottom: '1rem',
              }}
            >
              <Text field={props?.fields?.heading}></Text>
            </Typography>
            <Box
              style={{
                lineHeight: '1.875rem',
                color: theme.palette.white.main,
                fontSize: '20px',
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              <RichText field={props?.fields?.content} />
            </Box>
          </Box>
          {errors && (errors.email || errors.agree) && (
            <Box
              sx={{
                width: isMobile ? '100%' : '420px',
                alignSelf: 'center',
              }}
            >
              <Paper sx={{ p: 2, borderRadius: '8px', backgroundColor: '#E6F9F8' }}>
                <Stack direction="row" alignItems="center" sx={{ marginLeft: '0.5rem' }}>
                  <Image
                    style={{ color: theme.palette.error.main }}
                    priority
                    src={notificationIcon}
                    alt="Notification icon"
                  />
                  <Typography
                    sx={{
                      color: theme.palette.error.main,
                      fontWeight: 400,
                      lineHeight: '1.25rem',
                      marginLeft: '1rem',
                    }}
                  >
                    {`${errors?.email ? errors?.email?.message : errors.agree?.message}`}
                  </Typography>
                </Stack>
              </Paper>
            </Box>
          )}
          <Box
            sx={{
              width: isMobile ? '100%' : '420px',
              alignSelf: 'center',
              marginTop: errors && (errors.email || errors.agree) ? '0' : '2rem',
            }}
          >
            <Controller
              control={control}
              name="email"
              defaultValue={registration.email}
              rules={{
                required: {
                  value: props?.fields?.isEmailRequired?.value || false,
                  message: props?.fields?.requiredEmailValidationMessage?.value || '',
                },
                validate: async (value) => {
                  const regEx = RegExp(props?.fields?.emailRegex?.fields?.value?.value);
                  return regEx.test(value)
                    ? true
                    : props.fields.invalidInputEmailValidationMessage.value ?? '';
                },
              }}
              render={({ field }) => (
                <EmailAutoSugest
                  emailDomains={props?.fields?.emailDomains}
                  emailTitle={props?.fields?.emailTitle}
                  fieldProps={field}
                />
              )}
            />
          </Box>
          <Box sx={{ width: isMobile ? '100%' : '420px', alignSelf: 'center', paddingTop: '8px' }}>
            <Controller
              control={control}
              name="agree"
              defaultValue={registration.agree}
              rules={{
                required: {
                  value: props?.fields?.isConsentRequired?.value || false,
                  message: props?.fields?.consentRequiredValidationMessage?.value || '',
                },
              }}
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    className="checkboxLabel"
                    control={<CheckboxInput {...field} />}
                    label={
                      <RichText className="description" field={props?.fields?.consentDescription} />
                    }
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box sx={{ alignSelf: 'center', padding: '24px 0 10px 0' }} id="getstarted">
            <UIButton
              className={'register-btn btn'}
              id="get-started"
              type="submit"
              variant="outlined"
              tabIndex={!isValid ? -1 : 0}
              sx={style}
            >
              {t(props?.fields?.ctaLink?.value?.text)
                ? t(props?.fields?.ctaLink?.value?.text)
                : 'Get started'}
            </UIButton>
          </Box>
        </StRegistrationWrapper>
      </form>
    </StRegistrationContainer>
  );
};

export default Registartion;
