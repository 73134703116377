import { InputProps } from '../FormElements.types';
import AutocompleteInput from './AutocompleteInput';
import { Input } from './Input';
import { useDispatch, useSelector } from 'react-redux';
import { updatePrepopulateConfig } from '../../../../features/contactInfoSlice';
import { RootState } from '../../../../store/store';
import { useEffect } from 'react';
import { SITECORE_ZIPCODE_KEYS } from '../../../ContactInfo/constants';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { JOURNEYS, CONTACT_INFO_URLS, ZIPCODE_FIELDS } from '../../../../constants';

const InputWrapper = ({
  name,
  toolTip,
  placeholder,
  label,
  onChangeHandler,
  value,
  errors,
  countryFlag,
  maxLength,
  onBlur,
  showIsMobileOption,
  isMobileLabel,
  enableAutoComplete,
  setValue,
  countryCode,
  control,
  trigger,
  prePopulateFromScreener,
  isReadOnly,
  setError,
  addressZipcodeValidationErrorMessage,
  mobileNumberValidationMessageOnSMSConsent,
}: InputProps) => {
  const dispatch = useDispatch();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const geolocation = useSelector((state: RootState) => state.geolocation);
  const contactInfoUrl = useSelector((state: RootState) => state.contactInfoDetails.contactInfoUrl);
  const sitecoreFieldsForContactInfo = useSelector(
    (state: RootState) => state.contactInfoDetails.sitecoreFieldsForContactInfo
  );
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const isCaregiverPatientZipcodeFieldAvailable =
    sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode'];

  useEffect(() => {
    if (SITECORE_ZIPCODE_KEYS.indexOf(name) > -1) {
      dispatch(
        updatePrepopulateConfig({
          [name]: {
            readOnly: isReadOnly?.value,
            prePopulate: prePopulateFromScreener?.value,
          },
        })
      );
    }
  }, []);
  const prepopulateConfig = useSelector(
    (state: RootState) => state.contactInfoDetails.prepopulateConfig
  );
  const isFieldDisabled = (name: string) => {
    //TODO: also need to check if that value exists from screener
    if (
      prepopulateConfig[name]?.readOnly === true &&
      prepopulateConfig[name]?.prePopulate === true
    ) {
      return true;
    } else if (
      siteSettings?.enableGeoLocation?.value &&
      geolocation?.location?.zipcode !== undefined &&
      JOURNEYS.JOURNEY_01 === journeyType &&
      SITECORE_ZIPCODE_KEYS.includes(name)
    ) {
      //adult form check
      if (
        contactInfoUrl.includes(CONTACT_INFO_URLS.ADULT) &&
        name === ZIPCODE_FIELDS.ADULT_PATIENT_ZIPCODE
      ) {
        return true;
      }
      //minor form check
      else if (
        contactInfoUrl.includes(CONTACT_INFO_URLS.MINOR) &&
        name === ZIPCODE_FIELDS.MINOR_DELEGATE_ZIPCODE
      ) {
        return true;
      }
      //caregiver form
      else if (
        contactInfoUrl.includes(CONTACT_INFO_URLS.CAREGIVER) &&
        name === ZIPCODE_FIELDS.CAREGIVER_PATIENT_ZIPCODE
      ) {
        return true;
      } else if (
        contactInfoUrl.includes(CONTACT_INFO_URLS.CAREGIVER) &&
        !isCaregiverPatientZipcodeFieldAvailable &&
        name === ZIPCODE_FIELDS.CAREGIVER_DELEGATE_ZIPCODE
      ) {
        return true;
      }
    } else {
      return false;
    }
    return false;
  };
  return enableAutoComplete && !isEditorActive() ? (
    <AutocompleteInput
      onChangeHandler={onChangeHandler}
      value={value}
      label={label}
      name={name}
      setValue={setValue}
      trigger={trigger}
      onBlur={onBlur}
      setError={setError}
      toolTip={toolTip}
      placeholder={placeholder}
      errors={errors}
      countryFlag={countryFlag}
      countryCode={countryCode}
      maxLength={maxLength}
      isMobileLabel={isMobileLabel}
      showIsMobileOption={showIsMobileOption}
      enableAutoComplete={enableAutoComplete}
      control={control}
      addressZipcodeValidationErrorMessage={addressZipcodeValidationErrorMessage}
    />
  ) : (
    <Input
      onChangeHandler={onChangeHandler}
      value={value}
      label={label}
      setError={setError}
      name={name}
      onBlur={onBlur}
      toolTip={toolTip}
      placeholder={placeholder}
      disabled={isFieldDisabled(name)}
      errors={errors}
      countryFlag={countryFlag}
      countryCode={countryCode}
      maxLength={maxLength}
      isMobileLabel={isMobileLabel}
      showIsMobileOption={showIsMobileOption}
      enableAutoComplete={enableAutoComplete}
      control={control}
      mobileNumberValidationMessageOnSMSConsent={mobileNumberValidationMessageOnSMSConsent}
    />
  );
};

export { InputWrapper };
