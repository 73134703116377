import Typography from '@mui/material/Typography';
import Image from 'next/image';
import iconTick from '../../../../public/circle-tick.webp';
import { IProgressBarItemProps, ProgressBarItemStatus } from './ProgressBar.types';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { JssBarItem } from './ProgressBar.styles';
import { useTheme } from '@mui/material/styles';

interface ICircleNumberProps {
  id: number;
  isActive: boolean;
}
const CircleNumber: React.FC<ICircleNumberProps> = (props) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const neutral0 = theme.palette.neutral0.main;
  const neutral2 = theme.palette.neutral2.main;
  const neutral6 = theme.palette.neutral6.main;
  return (
    <div
      style={{
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: props?.isActive ? neutral0 : neutral6,
        backgroundColor: props?.isActive ? primaryColor : neutral2,
      }}
    >
      {props.id}
    </div>
  );
};

const CompletedIcon = () => <Image src={iconTick} height={24} width={24} alt="Tick icon" />;

const fallbackProps: IProgressBarItemProps = {
  fields: { name: { value: 'default' }, status: { value: ProgressBarItemStatus.disabled } },
  index: 1,
};

const ProgressBarItem: React.FC<IProgressBarItemProps> = (props) => {
  const name = props.fields?.name || fallbackProps.fields.name;
  const status = props.fields?.status || fallbackProps.fields.status;
  const index = props.index || fallbackProps.index;
  const isNoBackground = props?.isNoBackground || false;
  const isActive = status?.value === ProgressBarItemStatus.active;
  const isCompleted = status?.value === ProgressBarItemStatus.completed;
  const classActiveOrDisabled = isActive ? 'active-item' : 'disabled-item';
  const textClassNames = `item-text ${classActiveOrDisabled}`;
  return (
    <JssBarItem className={isNoBackground ? '' : classActiveOrDisabled}>
      {isCompleted ? <CompletedIcon /> : <CircleNumber id={index} isActive={isActive} />}
      <Typography className={textClassNames} variant="h6">
        <Text field={name} />
      </Typography>
    </JssBarItem>
  );
};

export default ProgressBarItem;
