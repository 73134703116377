import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { verifyMyProfileEmail, fetchProfileDetailsForCR } from '../../lib/utils/axiosCDPFetcher';
import { useDispatch } from 'react-redux';
import { setProfileData, updateIsEmailLinkExpired } from '../../features/crProfileSlice';
import { hideBanner, showBanner } from '../../features/crBannerSlice';
import { setCookie } from 'cookies-next';
import { CDP_EVENTS } from '../../../src/constants';
import * as cdpService from '../../services/CdpService';
import { hideLoader, showLoader } from '../../../src/features/loaderSlice';
import { setAuthentication } from '../../features/authSlice';
import * as CdpService from '../../services/CdpService';

const PROFILE_VERIFY_TOKEN_KEY = 'myProfileVerificationToken';

const useMyProfile = (route: any, sourceApp: any): any => {
  const router = useRouter();
  const dispatch = useDispatch();
  const currentPath = router?.asPath;
  const isCommunitiesPath = currentPath.includes('communities');
  const linkExpiredModelPopup: any = route?.fields?.linkExpiredModelPopup;
  const expiryTime = linkExpiredModelPopup?.fields?.expiryTime;
  const isEmailVerified = useRef(false);
  const isEmailExpired = useRef(false);
  const { sitecoreContext } = useSitecoreContext();

  const verifyEmail = async (verificationToken: string) => {
    const { data } = await verifyMyProfileEmail(`/api/contacts/VerifyProfile`, {
      verificationToken: verificationToken,
      expiryTime: parseInt(expiryTime?.value) || 0,
    });
    if (data.isLinkExpired) {
      dispatch(showLoader());
      isEmailExpired.current = true;
      dispatch(updateIsEmailLinkExpired(data?.isLinkExpired));
      return false;
    } else if (data.IsVerifiedEmail) {
      dispatch(hideBanner());
      isEmailVerified.current = true;
    }
    return true;
  };

  const triggerIdentityEvent = async (email: string) => {
    CdpService.identityEvent(email);
  };

  const getSetProfileData = async (loginToken?: string) => {
    const myProfileVerificationToken = sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
    if (myProfileVerificationToken) {
      const { data } = await fetchProfileDetailsForCR(myProfileVerificationToken);
      if (data) {
        dispatch(setProfileData(data));
        dispatch(hideLoader());
        if (data?.updatedGuestRef) {
          setTimeout(() => {
            setCookie('bx_guest_ref', data?.updatedGuestRef, {
              secure: true,
            });
            if (loginToken) {
              triggerIdentityEvent(data?.basicDetails?.email);
            }
          }, 3000);
        }
        if (data?.isVerifiedEmail === false && !isEmailVerified.current) {
          dispatch(showBanner());
        }
      }
    } else {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    const fetchMyProfile = async () => {
      const isMyProfilePage = (route?.fields?.isMyProfilePage as Field)?.value;
      const isOutcomePage = (route?.fields?.isOutcomePage as Field)?.value;
      let myProfileToken;
      let communityToken;
      if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
        return;
      }
      if (router?.isReady && isMyProfilePage) {
        myProfileToken = router.query?.myProfileToken as string;
        if (myProfileToken) {
          const result = await verifyEmail(myProfileToken);
          cdpService.event(CDP_EVENTS.MY_PROFILE_EMAIL_LINK_CLICK, {});
          if (result) {
            sessionStorage.setItem(PROFILE_VERIFY_TOKEN_KEY, myProfileToken);
            dispatch(setAuthentication(true));
          }
        }
      } else if (isCommunitiesPath) {
        communityToken = router.query?.communityToken as string;
        if (communityToken) {
          const result = await verifyEmail(communityToken);
          if (result) {
            sessionStorage.setItem(PROFILE_VERIFY_TOKEN_KEY, communityToken);
            dispatch(setAuthentication(true));
          }
        }
      }
      //TODO call getSetProfileData based on verifyEmail
      if (isMyProfilePage) {
        await getSetProfileData(myProfileToken);
      }
      if (isCommunitiesPath) {
        await getSetProfileData(communityToken);
      }
      if (sourceApp && sourceApp === 'CR' && isOutcomePage) {
        setTimeout(() => {
          dispatch(showLoader());
        }, 1000);

        await getSetProfileData();
        setTimeout(() => {
          getSetProfileData();
        }, 5000);
      }
    };
    fetchMyProfile();
  }, [route, router?.isReady]);
};

export default useMyProfile;
