import gql from 'graphql-tag';
import getGraphQLClient from '../../GraphQL/graphQLClient';

const TimeZonesGLQuery = gql`
  query TimeZone($rootItemId: String!, $templates: String!, $language: String!, $after: String!) {
    search(
      where: {
        AND: [
          { name: "_path", value: $rootItemId, operator: CONTAINS }
          { name: "_templates", value: $templates, operator: CONTAINS }
          { name: "_language", value: $language }
        ]
      }
      first: 50
      after: $after
    ) {
      total
      pageInfo {
        endCursor
        hasNext
      }
      results {
        id
        name
        path
        language {
          name
        }
        key: field(name: "key") {
          value
        }
        labelText: field(name: "label") {
          value
        }
      }
    }
  }
`;

export const getTimezoneQueryParameters = (language: string, countryId: string, after: string) => ({
  //TODO: rootItemId and template should be read from sitecore contect data
  templates: '{F6B8CCB4-1374-4556-9555-3D6D5151E0FB}',
  rootItemId: countryId || '{0BAEDE80-BEE8-493D-B548-B60A6935A31C}',
  after,
  language,
});

export const getAllTimeZones = async (language: string, countryId: string) => {
  let timeZoneList: any = [];
  let shouldQueryMoreItems = true;
  let after = '';

  while (shouldQueryMoreItems) {
    const graphQLClient = getGraphQLClient();
    const fetchResponse = await graphQLClient.request<any>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      TimeZonesGLQuery as any,
      getTimezoneQueryParameters(language, countryId, after)
    );
    timeZoneList = timeZoneList.concat(fetchResponse?.search?.results);
    shouldQueryMoreItems = fetchResponse.search.pageInfo.hasNext;
    after = fetchResponse.search.pageInfo.endCursor;
  }

  return timeZoneList;
};

export default TimeZonesGLQuery;
