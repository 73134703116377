import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

/**
 * Check if KIElements is enable or not
 * @param enableSponsorConsentKiElement - sponserspecific consent value form context sitesettings
 * @param kiElementsEnabled - kielements flag value form context sitesettings
 * @param isProtocalKiElementsEnabled - protocal level kielement flag value form selected site
 * @param isSiteKiElementsEnabled - site level kielement flag value form selected site
 * @returns - isKIEenabled: processed flag based on kiElementsEnabled, isSiteKiElementsEnabled & isProtocalKiElementsEnabled
 * @returns - isSponserSpecificConsentEnabled: EnableSponsorConsentKiElements value from context sitesettings
 */
const getKIElementsDecisionData = (
  enableSponsorConsentKiElement: Field<boolean>,
  isProtocalKiElementsEnabled: boolean,
  isSiteKiElementsEnabled: boolean
): { isKIEenabled: boolean; isSponserSpecificConsentEnabled: boolean } => {
  const isKIEenabled = isSiteKiElementsEnabled && isProtocalKiElementsEnabled;

  return {
    isKIEenabled,
    isSponserSpecificConsentEnabled: enableSponsorConsentKiElement?.value,
  };
};

export { getKIElementsDecisionData };
