/* eslint-disable @typescript-eslint/ban-ts-comment */
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  ImageField,
  RichTextField,
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import pnMergetagReplacer from '../../lib/utils/pnMergeTagReplacer';
import getCallCenterNumber from '../../store/selectors/getCallCenterNumber';
import { StNextImage } from '../shared/NextImage.styles';
import {
  PreScreenerHeader,
  PreScreenerTitle,
  PreScreenerWrapper,
  StyledDivider,
  PreScreenerText,
} from './PreScreenerBanner.style';
import {
  PreScreenerBannerFields,
  PreScreenerBannerProps,
  PreScreenerHeaderProps,
} from './PreScreenerBanner.types';
import { JOURNEYS } from '../../constants';
import { RootState } from '../../store/store';

const fallbackIcon: ImageField = {
  value: {
    src: '',
  },
};
const fallbackTextField: TextField = {
  value: '',
};
const fallbackRT: RichTextField = {
  value: '',
};
const initialProps: PreScreenerBannerFields = {
  heading: fallbackTextField,
  headingIcon: fallbackIcon,
  subHeading: fallbackRT,
  contentList: [{ fields: { content: fallbackRT, icon: fallbackIcon } }],
  applyGradient: {
    value: false,
  },
};

const Header: React.FC<PreScreenerHeaderProps> = ({
  heading,
  subHeading,
  headingIcon,
  applyGradient,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const themeColor = theme.palette.themeColor.main;
  const themeColorSecondary = theme.palette.themeColorSecondary.main;

  const iconDimentions = () => {
    if (isMobile) {
      return 48;
    }
    if (isTablet) {
      return 64;
    }
    return 80;
  };
  const backgroundColor = applyGradient.value
    ? `linear-gradient(to top, ${themeColor}, ${themeColorSecondary} 150%)`
    : themeColor;

  return (
    <Box
      id="pre-screener-header-banner"
      sx={{
        background: backgroundColor,
      }}
    >
      <PreScreenerWrapper>
        <PreScreenerHeader id="pre-screener-header">
          <StNextImage
            suppressHydrationWarning
            id="pre-screener-header-banner-image"
            field={headingIcon}
            height={iconDimentions()}
            width={iconDimentions()}
            style={{
              filter: 'brightness(0) invert(1)', // White color
            }}
          />
          <PreScreenerTitle id="pre-screener-header-banner-title">
            <Typography className="pre-screener-banner__header__title" variant="h3">
              <Text data-testid="title" field={heading} />
            </Typography>
            <StyledDivider />
            <PreScreenerText className="pre-screener-banner__header__body" field={subHeading} />
          </PreScreenerTitle>
        </PreScreenerHeader>
      </PreScreenerWrapper>
    </Box>
  );
};
const PreScreenerHeaderBanner: React.FC<PreScreenerBannerProps> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  // @ts-ignore
  const isContactInfoPage = sitecoreContext?.route?.fields?.isContactInfoPage?.value || false;
  // @ts-ignore
  const isHomePage = sitecoreContext?.route?.fields?.isHomePage?.value || false;

  const journeyType = useSelector((state: RootState) => state.cdpData.journeyType);
  const isJourneyType3: boolean = journeyType === JOURNEYS.JOURNEY_03;

  const callCenterNumber = getCallCenterNumber(sitecoreContext);
  const applyGradient = props.fields?.applyGradient || initialProps.applyGradient;
  const heading = props.fields?.heading || initialProps.heading;
  const headingIcon = props.fields?.headingIcon || initialProps.headingIcon;
  const originalSubHeading = props.fields?.subHeading || initialProps.subHeading;
  const [subHeading, setSubHeading] = useState(originalSubHeading);
  const siteSettings: any = sitecoreContext?.siteSettings;
  const isScreenerLandingPageEnabled = siteSettings?.enableScreenerLandingPage?.value;
  useEffect(() => {
    if (callCenterNumber.value) {
      const newSubHeadingValue = pnMergetagReplacer(
        originalSubHeading?.value,
        String(callCenterNumber.value)
      );
      setSubHeading({ ...originalSubHeading, value: newSubHeadingValue });
    }
  }, [callCenterNumber.value]);
  if (!props?.fields) return <></>;
  if (isContactInfoPage && !isJourneyType3) return <></>;
  if (isScreenerLandingPageEnabled && isHomePage) return <></>;
  return (
    <div id="pre-screener-wrapper">
      <Header
        heading={heading}
        subHeading={subHeading}
        headingIcon={headingIcon}
        applyGradient={applyGradient}
      />
    </div>
  );
};

export default PreScreenerHeaderBanner;
