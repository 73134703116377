import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Controller } from 'react-hook-form';
import { StFormLabel, StInputBase, StPlaceholderTypography } from './FormElements.styles';
import FormToolTip from './FormToolTip';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { JOURNEYS } from '../../../../src/constants';
import { useSelector } from 'react-redux';
import { SITECORE_STATE_KEYS } from '../../../components/ContactInfo/constants';
import { RootState } from '../../../../src/store/store';
import Box from '@mui/material/Box';

type FormDropDownProps = {
  regexValidationMessage: any;
  requiredFieldMessage: any;
  placeholder: Field<string>;
  type: any;
  isRequired: any;
  label: any;
  toolTip: any;
  errors: any;
  control: any;
  options: any;
  showInputLabel: boolean;
  rules?: any;
  name: any;
  isError?: any;
  renderError?: any;
  disabled?: boolean;
  setValue?: any;
};

type TabOption = {
  value: string;
  label?: string;
};

const FormDropDown = (props: FormDropDownProps): JSX.Element => {
  const geolocation = useSelector((state: RootState) => state.geolocation);
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const DropdownTypes = {
    NUMBER: 'number',
    STRING: 'string',
  };
  const sitecoreFieldsForContactInfo = useSelector(
    (state: RootState) => state.contactInfoDetails.sitecoreFieldsForContactInfo
  );
  let isPatientZipcodeFieldAvailable = false;
  if (
    sitecoreFieldsForContactInfo &&
    sitecoreFieldsForContactInfo['caregiver'] &&
    sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode']
  ) {
    isPatientZipcodeFieldAvailable =
      sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode'] || false;
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const disableState = (key: string) => {
    if (
      geolocation?.location?.state !== undefined &&
      JOURNEYS.JOURNEY_01 === journeyType &&
      SITECORE_STATE_KEYS.includes(key)
    ) {
      if (isPatientZipcodeFieldAvailable && key === 'CaregiverState') {
        return false;
      }
      const stateListArray = props?.options?.map((item: TabOption) => {
        return item.value;
      });

      if (
        stateListArray &&
        stateListArray.length > 0 &&
        stateListArray.includes(geolocation?.location?.state)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Controller
      name={props?.name?.value}
      control={props?.control}
      rules={props?.rules}
      defaultValue={''}
      render={({ field: { onChange, value, onBlur } }) => (
        <FormControl fullWidth>
          {props?.showInputLabel && (
            <StFormLabel htmlFor={props.name.value}>
              <Text field={props.label} />
              {props?.toolTip && <FormToolTip value={props?.toolTip} />}
            </StFormLabel>
          )}
          <Select
            labelId={props.label.value}
            defaultValue={''}
            value={value || 'none'}
            onChange={onChange}
            onOpen={() => setIsExpanded(true)}
            onClose={() => setIsExpanded(false)}
            MenuProps={MenuProps}
            disabled={disableState(props?.name?.value)}
            input={
              <StInputBase
                id={props.name.value}
                sx={{
                  border: (theme: Theme) =>
                    props.isError || props?.errors[props.name.value]
                      ? `1px solid ${theme.palette.error.main} !important`
                      : `1px solid ${theme.palette.neutral4.main}`,
                  padding: '10px 8px 8px 8px !important',
                }}
                inputProps={{
                  onBlur: () => {
                    onBlur();
                  },
                }}
              />
            }
          >
            {!isExpanded && (
              <MenuItem value="none" disabled>
                <StPlaceholderTypography>
                  <Text field={props?.placeholder} />
                </StPlaceholderTypography>
              </MenuItem>
            )}
            {props?.options?.map((item: TabOption, index: any) => (
              <MenuItem
                key={index}
                value={props?.type === DropdownTypes.NUMBER ? Number(index) : item.value}
                sx={{
                  fontFamily: 'Proxima Nova',
                  fontSize: '14px',
                  lineHeight: '24px',
                  fontWeight: 400,
                  ':focus-visible': { outline: 'none' },
                }}
              >
                {item?.label || item?.value}
              </MenuItem>
            ))}
          </Select>
          {props?.renderError && (
            <Box sx={{ color: (theme) => theme.palette.error.main, fontSize: '0.8125rem' }}>
              {props.renderError(props?.name?.value)}
            </Box>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormDropDown;
