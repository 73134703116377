import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContactInfoContextProvider from '../../components/ContactInfo/ContactInfoContextProvider';
import FormBuilder from '../../components/ContactInfo/FormBuilder';
import { useForm } from 'react-hook-form';
import { StFormContainer } from './MyProfileContact.style';
import { UIButton } from '../../components/shared/UIButton.styles';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateConsents, updateGender } from 'components/ContactInfo/helper';
import moment from 'moment';
import { PHONE_NUMBER, contactFormFieldType } from '../../components/ContactInfo/constants';

export const MONTH_MAPPER = {
  4: 'April',
  8: 'August',
  12: 'December',
  2: 'February',
  1: 'January',
  7: 'July',
  6: 'June',
  3: 'March',
  5: 'May',
  11: 'November',
  10: 'October',
  9: 'September',
};

const MyProfileContact = (props: any): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const buttonProps = props?.fields?.items[2];
  const myProfileData = useSelector((state: RootState) => state.crProfile.profileData);
  const getFormDataDefaultValue = () => {
    let dobDate: string | null = null;
    let dobYear: number | null = null;
    let dobDay: number | null = null;
    let dobMonth: string | null = null;

    if (myProfileData?.basicDetails?.dob) {
      dobDate = moment.parseZone(myProfileData?.basicDetails?.dob).format();
      dobYear = moment.parseZone(dobDate).year();
      dobDay = moment.parseZone(dobDate).date();
      dobMonth = MONTH_MAPPER[(moment.parseZone(dobDate).month() + 1) as keyof typeof MONTH_MAPPER];
    } else {
      dobYear = null;
      dobDay = null;
      dobMonth = null;
    }

    return {
      FirstName: myProfileData?.basicDetails?.firstName,
      LastName: myProfileData?.basicDetails?.lastName,
      ContactNumber: myProfileData?.basicDetails?.mobile,
      CrAddress: myProfileData?.basicDetails?.address,
      City: myProfileData?.basicDetails?.city,
      State: myProfileData?.basicDetails?.state,
      Zipcode: myProfileData?.basicDetails?.zipCode,
      Day: dobDay,
      Month: dobMonth,
      Year: dobYear,
      Gender: myProfileData?.basicDetails?.gender,
      Email: myProfileData?.basicDetails?.email,
      isMobile: myProfileData?.basicDetails?.isMobile,
    };
  };
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'onBlur' });
  const formHandlerObject = useForm({
    mode: 'all',
    defaultValues: async () => getFormDataDefaultValue(),
  });

  const [ContactNumberField] = useState(function () {
    let value: any = { fields: { mobileNumberValidationMessageOnSMSConsent: { value: '' } } };
    props?.fields?.items?.map((subItems: any) => {
      if (subItems?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE) {
        subItems?.fields?.contactFieldList?.map((contactFieldSubItem: any) => {
          if (PHONE_NUMBER.indexOf(contactFieldSubItem?.fields?.key?.value) !== -1) {
            value = contactFieldSubItem;
          }
        });
      }
    });
    return value;
  });

  const { watch, trigger, setError: setError1 } = formHandlerObject;

  const validateSMSandIsMobile = async (
    value: any,
    trigger: any,
    setError1: any,
    ContactNumberField: { fields: { mobileNumberValidationMessageOnSMSConsent: { value: any } } }
  ): Promise<void> => {
    const isSecondarySMSConsentVisible: any = document.querySelector(
      `input[name="SecondaryConsentMediumSMS"]`
    );

    if (
      !!value?.isMobile === false &&
      value?.SecondaryConsentMediumSMS &&
      isSecondarySMSConsentVisible?.type === 'checkbox'
    ) {
      setError1('isMobile', {
        message:
          ContactNumberField?.fields?.mobileNumberValidationMessageOnSMSConsent?.value ||
          `SMS (primary or study specific consents) is selected, please select Is mobile checkbox`,
      });
    } else if (!!value?.isMobile === false && !value?.SecondaryConsentMediumSMS) {
      await trigger('isMobile');
    } else if (value?.isMobile) {
      await trigger('isMobile');
    }
  };

  useEffect(() => {
    const subscription = watch((v, n) => {
      if (n?.name === 'isMobile' || n?.name === 'SecondaryConsentMediumSMS') {
        validateSMSandIsMobile(v, trigger, setError1, ContactNumberField);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSubmit = async () => {
    const formData = formHandlerObject?.getValues();
    const isSave = await props.handleMyProfileSave(formData, myProfileData?.isVerifiedEmail);
    clearErrors('email');
    if (isSave === false) {
      setError('email', {
        type: 'manual',
        message:
          buttonProps?.fields?.emailExistsErrorMessage?.value ||
          'entered email address is linked to another account',
      });
    }
  };

  useEffect(() => {
    if (myProfileData) {
      const existingValues = formHandlerObject?.getValues();
      formHandlerObject.reset({ ...existingValues, ...getFormDataDefaultValue() });
    }
  }, [myProfileData]);

  const populateExistingUserConsents = async (consents: any) => {
    try {
      updateConsents('secondary', consents);
    } catch (_e) {
      return;
    }
  };

  const populateGender = async (genderValue: any) => {
    try {
      updateGender(genderValue);
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    if (myProfileData?.basicDetails?.secondaryConsent) {
      setTimeout(() => {
        populateExistingUserConsents(myProfileData?.basicDetails?.secondaryConsent);
      }, 1500);
    }
    if (myProfileData?.basicDetails?.gender) {
      setTimeout(() => {
        populateGender(myProfileData?.basicDetails?.gender);
      }, 1500);
    }
  }, [myProfileData]);

  return (
    <ContactInfoContextProvider formHandlerObject={formHandlerObject} zipCode={null}>
      <StFormContainer isMobile={isMobile}>
        {/* <pre>{JSON.stringify(watch(), null, 4)}</pre> */}
        <form onSubmit={formHandlerObject.handleSubmit(handleSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormBuilder data={props?.fields?.items[1]} />

            <UIButton
              variant={buttonProps?.fields?.buttonVariant?.value}
              sx={{
                width: '203px',
                minWidth: '157px !important',
                alignSelf: isMobile ? 'center' : 'flex-start',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px 40px',
                margin: isMobile ? '1.5rem 0 0.5rem 0' : '1.5rem 0 1rem 0',
              }}
              aria-label={buttonProps?.fields?.ariaLabel?.value}
              type="submit"
            >
              {buttonProps?.fields?.link?.value?.text || 'Save'}
            </UIButton>
            {errors && errors.email && (
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: 400,
                  lineHeight: '1.25rem',
                }}
              >
                {`${errors?.email?.message}`}
              </Typography>
            )}
          </Box>
        </form>
      </StFormContainer>
    </ContactInfoContextProvider>
  );
};

export default MyProfileContact;
