import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { Field, ImageField, Image } from '@sitecore-jss/sitecore-jss-nextjs';
import { InputHTMLAttributes, useState } from 'react';
import { UILink } from '../../shared/Links/UILink.styles';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { Typography } from '@mui/material';

export type toggleViewPanelProps = {
  option1: Field<string>;
  option2: Field<string>;
  option1Component: React.ReactNode;
  option2Component: React.ReactNode;
  inputProps?: InputHTMLAttributes<HTMLInputElement> | undefined;
  width?: string;
  minWidth?: string;
  isStudyLocation?: boolean;
  background?: string;
  bestMatchsitesText: string;
  activePannel?: any;
  groupTwoSites?: any;
  toggleActivePanel?: any;
  bestMatchSitesIcon?: ImageField;
};

interface TabPanelProps {
  index: number;
  value: boolean;
  children?: React.ReactNode;
  isTablet: boolean;
  ariaLabel: string;
  background?: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, isTablet, ariaLabel, background, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== !!index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={ariaLabel}
      {...other}
      style={{
        width: '100%',
      }}
    >
      {value === !!index && (
        <Box
          sx={{
            background: background
              ? background
              : (theme: Theme) =>
                  isTablet
                    ? index != 0
                      ? theme.palette.neutral2.main
                      : 'none'
                    : theme.palette.backgroundColorType3.main,
            mt: 2,
            px: index === 1 ? 0 : isTablet ? 4 : 2,
            py: index === 0 ? 0 : 4,
          }}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

const ToggleViewPanel = (props: toggleViewPanelProps): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const [isToggled, setIsToggled] = useState(true);
  const { isStudyLocation } = props;
  const { width, minWidth } = props;
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const containerStyle = {
    width: width || 'inherit',
    minWidth: minWidth || 'inherit',
  };
  return isDesktop ? (
    <Grid item container direction="row" style={containerStyle} sx={{ borderRadius: '10px' }}>
      <Grid
        item
        data-testid={'option1Component'}
        sm={12}
        md={isStudyLocation ? 4 : 5}
        sx={{
          background: theme.palette.neutral2.main,
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
          borderBottomLeftRadius: '0.5rem',
          borderTopLeftRadius: '0.5rem',
        }}
      >
        {props?.option1Component}
      </Grid>
      <Grid
        item
        data-testid={'option2Component'}
        sm={12}
        md={isStudyLocation ? 8 : 7}
        sx={{
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
          borderBottomRightRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
          backgroundColor: theme.palette.neutral2.main,
        }}
      >
        {props?.option2Component}
      </Grid>
    </Grid>
  ) : (
    <Grid item container>
      <ToggleSwitch
        label1={props?.option1}
        label2={props?.option2}
        defaultChecked={isToggled}
        isToggled={isToggled}
        onToggle={handleToggle}
        inputProps={props?.inputProps}
      />
      {/* Note that index 1 resembles the initially checked value ,ie, in this case List */}

      <CustomTabPanel
        data-testid={'option1Component-device'}
        value={isToggled}
        index={1}
        isTablet={isTablet}
        ariaLabel={props?.option1?.value}
      >
        {props?.option1Component}
      </CustomTabPanel>
      <CustomTabPanel
        data-testid={'option2Component-device'}
        value={isToggled}
        index={0}
        isTablet={isTablet}
        ariaLabel={props?.option2?.value}
      >
        <Box>
          {!isDesktop &&
            !isToggled &&
            props?.groupTwoSites &&
            props?.groupTwoSites?.length > 0 &&
            props?.activePannel === 'groupTwo' && (
              <UILink
                className="btn"
                onClick={props?.toggleActivePanel}
                sx={{
                  '&: hover': {
                    borderBottom: 'none !important',
                  },
                }}
              >
                <Image field={props?.bestMatchSitesIcon} />
                <Typography component={'span'} sx={{ padding: 0, margin: '0 5px 0 0' }}>
                  {props.bestMatchsitesText}
                </Typography>
              </UILink>
            )}
        </Box>
        {props?.option2Component}
      </CustomTabPanel>
    </Grid>
  );
};

export default ToggleViewPanel;
