import { useState, useEffect, useMemo } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
const ACTIVE_PANEL = {
  GROUP_ONE: 'groupOne',
  GROUP_TWO: 'groupTwo',
  ALL: 'all',
};
const isFeatureEnabled = true; // Can be made dynamic if needed
const useSiteSelectionHelper = (props: any, sitesData: any, cdpstate: any) => {
  console.log(props);
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const pageSize = siteSettings?.maxNumberOfSites?.value || 5;
  const [allSites, setAllSites] = useState([]);
  const [groupOneSites, setGroupOneSites] = useState([]);
  const [groupTwoSites, setGroupTwoSites] = useState([]);

  useEffect(() => {
    if (sitesData) {
      setAllSites(sitesData);
      setGroupOneSites(sitesData.filter((site: { tier: number }) => site.tier === 1));
      setGroupTwoSites(sitesData.filter((site: { tier: number }) => site.tier === 2));
    }
  }, [cdpstate]);

  const enableSingleScreen = groupOneSites.length > 0 && groupTwoSites.length > 0 ? false : true;
  const showViewButton =
    groupOneSites.length > pageSize || groupTwoSites.length > pageSize ? true : false;
  const showViewButtonFirstScreen = groupOneSites?.length > pageSize ? true : false;
  const showViewButtonSecondScreen = groupTwoSites?.length > pageSize ? true : false;
  const [activePannel, setActivePannel] = useState(
    isFeatureEnabled ? ACTIVE_PANEL.GROUP_ONE : ACTIVE_PANEL.ALL
  );

  if (
    groupOneSites &&
    groupOneSites?.length == 0 &&
    groupTwoSites?.length > 0 &&
    activePannel != ACTIVE_PANEL.ALL
  ) {
    setActivePannel(ACTIVE_PANEL.ALL);
  }
  const [shouldShowViewMore, setShouldShowViewMore] = useState(() => {
    if (activePannel === ACTIVE_PANEL.GROUP_ONE) {
      return groupOneSites?.length > 0 ? groupOneSites?.length > pageSize : true;
    } else {
      return groupTwoSites?.length > pageSize;
    }
  });

  const sitesToShow = useMemo(() => {
    if (activePannel === ACTIVE_PANEL.GROUP_ONE && shouldShowViewMore) {
      return groupOneSites.slice(0, pageSize);
    } else if (
      (activePannel === ACTIVE_PANEL.GROUP_TWO || activePannel === ACTIVE_PANEL.ALL) &&
      shouldShowViewMore
    ) {
      return groupTwoSites.slice(0, pageSize);
    }
    if (!shouldShowViewMore && activePannel === ACTIVE_PANEL.GROUP_ONE) {
      return groupOneSites;
    }
    if (
      (!shouldShowViewMore && activePannel === ACTIVE_PANEL.GROUP_TWO) ||
      activePannel === ACTIVE_PANEL.ALL
    ) {
      return groupTwoSites;
    }
    return allSites.slice(0, pageSize);
  }, [activePannel, groupOneSites, groupTwoSites, allSites, pageSize, shouldShowViewMore]);

  const viewAllSites = () => {
    if (activePannel === ACTIVE_PANEL.GROUP_ONE) {
      setShouldShowViewMore(false);
    } else if (activePannel === ACTIVE_PANEL.GROUP_TWO) {
      setShouldShowViewMore(false);
    } else if (activePannel === ACTIVE_PANEL.ALL) {
      setShouldShowViewMore(false);
    }
  };
  const viewGroupOneSites = () => {
    if (activePannel === ACTIVE_PANEL.GROUP_ONE) {
      setShouldShowViewMore(true);
    } else if (activePannel === ACTIVE_PANEL.GROUP_TWO) {
      setShouldShowViewMore(true);
    } else if (activePannel === ACTIVE_PANEL.ALL) {
      setShouldShowViewMore(true);
    }
  };
  const toggleActivePanel = () => {
    if (activePannel === ACTIVE_PANEL.GROUP_ONE) {
      setActivePannel(ACTIVE_PANEL.GROUP_TWO);
      setShouldShowViewMore(true);
      setTimeout(() => {
        const firstSiteElement = document.getElementById('first-site-groupTwo');
        const sitesListElement = document.getElementById('sites-list');
        if (firstSiteElement) {
          firstSiteElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (sitesListElement) {
          sitesListElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    } else if (activePannel === ACTIVE_PANEL.GROUP_TWO) {
      setActivePannel(ACTIVE_PANEL.GROUP_ONE);
      setShouldShowViewMore(true);
      setTimeout(() => {
        const firstSiteElement = document.getElementById('first-site-groupOne');
        const sitesListElement = document.getElementById('sites-list');
        if (firstSiteElement) {
          firstSiteElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (sitesListElement) {
          sitesListElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    } else if (activePannel === ACTIVE_PANEL.ALL) {
      setActivePannel(ACTIVE_PANEL.ALL);
      setShouldShowViewMore(true);
      const sitesListElement = document.getElementById('sites-list');
      if (sitesListElement) {
        sitesListElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const resetActivePanelForWfOne = () => {
    if (activePannel === ACTIVE_PANEL.GROUP_TWO) {
      setActivePannel(ACTIVE_PANEL.GROUP_ONE);
      setShouldShowViewMore(true);
    }
  };
  return {
    sitesToShow,
    shouldShowViewMore,
    viewAllSites,
    viewGroupOneSites,
    toggleActivePanel,
    activePannel,
    groupTwoSites,
    enableSingleScreen,
    showViewButton,
    showViewButtonFirstScreen,
    showViewButtonSecondScreen,
    resetActivePanelForWfOne,
  };
};
export default useSiteSelectionHelper;
