import NextLink from 'next/link';
import Typography from '@mui/material/Typography';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from './HomeIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { IBreadcrumb } from './Breadcrumbs.types';

const HomeBreadCrumb = () => (
  <HomeIcon sx={{ mr: 0.5, mb: '2px', height: '1.1rem', width: '1.1rem' }} />
);

const Breadcrumbs: React.FC<IBreadcrumb> = (props) => {
  const { item } = props;
  const theme = useTheme();
  if (!item) {
    return <></>;
  }
  const getAncestors = () => {
    if (Array.isArray(item?.ancestors)) {
      return [...item.ancestors].reverse();
    }
    return [];
  };

  return (
    <div role="presentation">
      <MUIBreadcrumbs
        data-testid="breadcrumb"
        aria-label={item?.pageTitle?.value}
        separator={
          <ChevronRightIcon fontSize="small" sx={{ color: theme.palette.neutral6.main }} />
        }
        sx={{
          '.MuiBreadcrumbs-separator': {
            marginInline: '2px',
          },
        }}
      >
        {/* Render ancestors breadcrumbs */}
        {getAncestors().map((ancestor, index) => (
          <NextLink
            key={index}
            href={ancestor.url?.path || ''}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: theme.palette.primary.main,
            }}
          >
            {ancestor.url?.path === '/' ? HomeBreadCrumb() : ancestor.pageTitle?.value}
          </NextLink>
        ))}
        {/* Render current page breadcrumb */}
        {item?.pageTitle?.value && (
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            color={theme.palette.black.main}
            fontWeight={600}
          >
            {item.pageTitle.value}
          </Typography>
        )}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
