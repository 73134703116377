import {
  StFooterContainer,
  StPrimaryLinkContainer,
  StSecondaryLinkContainer,
  StSocialMediaContainer,
  StCopyRightText,
  StDisclaimerText,
  StFooterWrapper,
} from './Footer.style';
import {
  RichText,
  NextImage,
  Field,
  LinkField,
  ImageField,
  ImageFieldValue,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Grid from '@mui/material/Grid';
import PrimaryLinks from '../shared/PrimaryLinks/PrimaryLinks';
import SecondaryLinks from '../shared/SecondaryLinks/SecondaryLinks';
import SocialMediaLinks from '../shared/SocialMediaLinks/SocialMediaLinks';
import { ComponentProps } from 'lib/component-props';
import { useEffect, useState } from 'react';

interface ISecondaryLinkItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    image: ImageField;
    link: LinkField;
  };
}

interface ISocialMediaLinkItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    image: ImageField;
    link: LinkField;
  };
}
type FooterProps = ComponentProps & {
  fields: {
    sponsorLogo?: ImageField;
    copyrightText: Field<string>;
    primaryLinks?: Array<LinkField>;
    secondaryLinks: Array<ISecondaryLinkItem>;
    socialMediaIconList: Array<ISocialMediaLinkItem> | [];
    disclaimerText?: Field<string>;
    bgColor?: Field<string>;
    fontColor?: Field<string>;
    hoverColor?: Field<string>;
    iconColor?: Field<string>;
    LinkColor?: Field<string>;
  };
};

const Footer = (props: FooterProps): JSX.Element => {
  const [copyRightText, setCopyRightText] = useState<any>(props?.fields?.copyrightText?.value);
  useEffect(() => {
    const fullYear = new Date().getFullYear();
    const newString = props?.fields?.copyrightText?.value
      ?.replace(/\[currentYear\]/g, `${fullYear}`)
      ?.replace(/\\[copyrightyear]/g, `${fullYear}`);

    setCopyRightText(newString as any);
  }, [props?.fields?.copyrightText]);

  if (!props?.fields) return <></>;
  const primaryLinks =
    props?.fields?.primaryLinks?.map(({ name, fields }: any) => ({
      name,
      link: fields.link,
    })) || [];
  const secondaryLinks =
    props?.fields?.secondaryLinks?.map(({ name, fields }: any) => ({
      name,
      link: fields.link,
      image: fields.image,
    })) || [];
  const isCRApp = (props as any)?.params?.footerVariantFlag === 'CR' || false;
  const socialMediaIconsLength = props?.fields?.socialMediaIconList?.length || 0;
  const linkColor = props?.fields?.LinkColor?.value || '#ffffff';
  return (
    <StFooterWrapper id="footer" bgColor={props?.fields?.bgColor?.value}>
      <StFooterContainer
        container
        component="footer"
        sx={{
          color: props?.fields?.fontColor?.value || '#ffff',
        }}
      >
        <StPrimaryLinkContainer item container xs={12} md={12} lg={8}>
          {isCRApp ? (
            <PrimaryLinks primaryLinks={primaryLinks} linkFontColor={linkColor} />
          ) : (
            props?.fields?.sponsorLogo?.value?.src && (
              <Grid item id="footer-logo">
                <NextImage field={props?.fields?.sponsorLogo as ImageFieldValue} />
              </Grid>
            )
          )}
        </StPrimaryLinkContainer>
        {socialMediaIconsLength > 0 && (
          <StSocialMediaContainer item container xs={12} md={12} lg={4}>
            <SocialMediaLinks
              socialMediaIconList={props?.fields?.socialMediaIconList}
              hoverColor={props?.fields?.hoverColor?.value}
              iconColor={props?.fields?.iconColor?.value}
            />
          </StSocialMediaContainer>
        )}

        <StSecondaryLinkContainer item container xs={12} md={12} lg={8}>
          <SecondaryLinks
            secondaryLinks={secondaryLinks}
            linkFontColor={linkColor}
            hoverColor={props?.fields?.hoverColor?.value}
          />
        </StSecondaryLinkContainer>
        <StCopyRightText item container xs={12} md={12} lg={4}>
          <RichText field={{ ...props?.fields?.copyrightText, value: copyRightText }} />
        </StCopyRightText>
        <StDisclaimerText>
          {!props?.fields?.disclaimerText ? null : (
            <RichText field={props?.fields?.disclaimerText} />
          )}
        </StDisclaimerText>
      </StFooterContainer>
    </StFooterWrapper>
  );
};

export default Footer;
