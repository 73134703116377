import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss-nextjs/graphql';

const getGraphQLClient = () => {
  const graphQLEndpointPath = process.env.GRAPH_QL_ENDPOINT_PATH || '/sitecore/api/graph/edge';
  const sitecoreApiHost = process.env.SITECORE_API_HOST || '';
  const graphQLEndpoint =
    process.env.GRAPH_QL_ENDPOINT || `${sitecoreApiHost}${graphQLEndpointPath}`;
  const sitecoreApiKey = process.env.SITECORE_API_KEY || 'no-api-key-set';

  const graphQLClient = new GraphQLRequestClient(graphQLEndpoint, {
    apiKey: sitecoreApiKey,
  });

  return graphQLClient;
};

export default getGraphQLClient;
