import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { JSSLink } from '../../components/shared/Links/JSSLink.styles';
import { StyledDivider, StTextContainer } from './MinorDNQ.styles';
import { StNextImage } from '../../components/shared/NextImage.styles';
import { MinorDNQProps } from './MinorDNQ.types';
import { StDTPGridContainer } from '../../components/shared/Container/Container.styles';

const MinorDNQ = (props: MinorDNQProps): JSX.Element => {
  const theme = useTheme();
  if (!props?.fields) return <></>;
  const { icon, content, link, buttonVariant } = props?.fields;
  return (
    <StDTPGridContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap="2rem"
        sx={{
          marginTop: '2rem',
        }}
      >
        <StNextImage
          suppressHydrationWarning
          data-testid="icon"
          field={icon}
          width={97}
          height={87}
        />
        <StyledDivider />
        <StTextContainer>
          <Box
            data-testid="content"
            textAlign="center"
            sx={{
              color: (theme: any) => theme.palette.themeColor.main,
              fontSize: '2rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '2.5rem',
            }}
          >
            <RichText field={content} />
          </Box>
        </StTextContainer>
        <JSSLink
          data-testid="link"
          className={'dnq-btn btn'}
          variant={buttonVariant?.value || 'contained'}
          field={link}
          sx={{
            paddingInline: '22px',
            minWidth: 'auto',
            [theme.breakpoints.down('sm')]: {
              paddingBlock: '6px',
            },
          }}
        />
      </Box>
    </StDTPGridContainer>
  );
};

export default MinorDNQ;
