import gql from 'graphql-tag';
import getGraphQLClient from '../../GraphQL/graphQLClient';
// import graphqlClientFactory from 'lib/graphql-client-factory';
const StateListGLQuery = gql`
  query StateList($rootItemId: String!, $templates: String!, $language: String!, $after: String!) {
    search(
      where: {
        AND: [
          { name: "_path", value: $rootItemId, operator: CONTAINS }
          { name: "_templates", value: $templates, operator: CONTAINS }
          { name: "_language", value: $language }
        ]
      }
      first: 20
      after: $after
    ) {
      total
      pageInfo {
        endCursor
        hasNext
      }
      stateList: results {
        stateName: name
        key: field(name: "key") {
          value
        }
        displayText: field(name: "value") {
          value
        }
        label: field(name: "label") {
          value
        }
        isterritoryState: field(name: "isTerritoryState") {
          value
        }
        countryCodeTerritory: field(name: "cc_TerritoryState") {
          value
        }
      }
    }
  }
`;

export const getStateListGLQueryParameters = (
  language: string,
  countryId: string,
  after: string
) => ({
  //TODO: rootItemId and template should be read from sitecore contect data
  templates: '{371FB8E1-C9DC-4C50-9282-14239BF4862E}',
  rootItemId: countryId || '{0BAEDE80-BEE8-493D-B548-B60A6935A31C}',
  after,
  language,
});

export const getAllStates = async (language: string, countryId: string) => {
  let stateList: any = [];
  let shouldQueryMoreItems = true;
  let after = '';

  while (shouldQueryMoreItems) {
    const graphQLClient = getGraphQLClient();
    const fetchResponse = await graphQLClient.request<any>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      StateListGLQuery as any,
      getStateListGLQueryParameters(language, countryId, after)
    );
    stateList = stateList.concat(fetchResponse?.search?.stateList);
    shouldQueryMoreItems = fetchResponse.search.pageInfo.hasNext;
    after = fetchResponse.search.pageInfo.endCursor;
  }
  return stateList;
};

export default StateListGLQuery;
