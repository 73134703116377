import * as React from 'react';
import { useSelector } from 'react-redux';
// import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { IProgressBarFields, IProgressBarProps } from './ProgressBar.types';
import ProgressBarItem from './ProgressBarItem';
import { JssBarContainer } from './ProgressBar.styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CollapsibleProgressBar from './CollapsibleProgressBar/CollapsibleProgressBar';
import { JOURNEYS } from '../../../constants/index';
import { RootState } from '../../../store/store';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

const journeyProgresMap: Record<string, string> = {
  [JOURNEYS.JOURNEY_01]: 'progressBarItemsJ1',
  [JOURNEYS.JOURNEY_02]: 'progressBarItemsJ2',
  [JOURNEYS.JOURNEY_03]: 'progressBarItemsJ3',
};

// const fallbackFields: IProgressBarFields = {
//   progressBarItems: [
//     {
//       fields: {
//         name: { value: 'default' },
//         status: { value: ProgressBarItemStatus.disabled },
//       },
//     },
//   ],
// };
export const NormalProgressBar: React.FC<IProgressBarFields> = (props) => {
  // let progressBarItems = fallbackFields.progressBarItems;
  let progressBarItems: any = [];
  if (Array.isArray(props.progressBarItems)) {
    progressBarItems = props.progressBarItems;
  }
  return (
    <JssBarContainer>
      {progressBarItems!.map((item: any, index: number) => (
        <ProgressBarItem index={index + 1} key={index} fields={item.fields} />
      ))}
    </JssBarContainer>
  );
};

const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  let journeyType = useSelector((state: RootState) => state.cdpData.journeyType);
  if (!props?.fields) return <></>;

  // set fallback as initial data
  // let progressBarItems = fallbackFields.progressBarItems;
  let progressBarItems: any = [];

  //progress bar is dynamic in behavior depends on journeyType
  //but in exp editor we are setting in it to some default value
  if (isEditorActive()) {
    journeyType = JOURNEYS.JOURNEY_01;
  }
  if (journeyType && typeof journeyType === 'string') {
    const progressBarItemsPropertyName = journeyProgresMap[journeyType];
    if (progressBarItemsPropertyName) {
      const newProgressBarItems = props.fields?.[progressBarItemsPropertyName];
      if (Array.isArray(newProgressBarItems) && newProgressBarItems.length > 0) {
        progressBarItems = newProgressBarItems;
      }
    }
  }

  let isCollapse = false; // Should ProgressBar Collapse on Mobile view
  if ((props as any)?.params?.isCollapse === '1') {
    isCollapse = true;
  }

  return (
    <>
      <Box
        sx={{
          display: { xs: 'inherit', md: 'none' },
        }}
      >
        {isCollapse ? (
          <CollapsibleProgressBar progressBarItems={progressBarItems} />
        ) : (
          <Paper elevation={3} sx={{ padding: '32px' }}>
            <NormalProgressBar progressBarItems={progressBarItems} />
          </Paper>
        )}
      </Box>
      <Box
        data-testid={'progressbartestid'}
        sx={{
          display: { xs: 'none', md: 'inherit' },
        }}
      >
        {/* Tablet and Desktop */}
        <NormalProgressBar progressBarItems={progressBarItems} />
      </Box>
    </>
  );
};
export default ProgressBar;
