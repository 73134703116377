import { IScreenerOutput } from '../features/screenerOutputSlice';

/**
 * Send the screener main data to the accepted database schema structure
 * @param potentialParticipant_ID - participant id
 * @param screenerID - screenerID
 * @param screenerResponseID - screener sessionId
 * @returns - transformed data to be saved in database
 */
const transformDataToSaveMain = (
  potentialParticipant_ID: string,
  potentialId: number,
  screenerID: string,
  screenerResponseID: string,
  screenerType: string,
  tenantKey: string,
  enrollmentId: string
): any => {
  return {
    preScreenerMain: {
      potentialId,
      potentialParticipant_ID,
      screenerID,
      screenerResponseID,
      screenerType: screenerType ? screenerType : 'Alchemer',
      screenerStatus: 'disqualified',
      screenerCompleted: false,
      base64EncodedSGResponse: null,
      tenantKey,
      enrollmentId,
    },
    preScreenerOutput: {
      screenerOutput: {},
    },
    tenantKey,
    enrollmentId,
  };
};

/**
 * Transform the screener output data to the accepted database schema structure
 * @param data - screener output data
 * @param potentialParticipant_ID - participant id
 * @param screenerID - screenerID
 * @param screenerResponseID - screener sessionId
 * @returns - transformed data to be saved in database
 */
const transformDataToSaveOutput = (
  potentialParticipant_ID: string,
  potentialId: number,
  screenerID: string,
  screenerResponseID: string,
  data: IScreenerOutput,
  screenerType: string,
  cohortAdditionalParameters: string,
  tenantKey: string,
  enrollmentId: string
): any => {
  const {
    pId,
    pdfContent,
    pSec,
    r,
    ed,
    g,
    d,
    m,
    y,
    zipCode,
    cm,
    b,
    et,
    ch,
    patientType,
    ac,
    lastQuestion,
    condition,
  } = data;
  return {
    preScreenerMain: {
      potentialId,
      potentialParticipant_ID,
      screenerID,
      screenerResponseID,
      screenerType: screenerType ? screenerType : 'Alchemer',
      screenerStatus: pSec ? 'qualified' : 'disqualified',
      screenerCompleted: lastQuestion ? true : false,
      base64EncodedSGResponse: pdfContent,
      tenantKey,
      enrollmentId,
    },
    preScreenerOutput: {
      screenerOutput: {
        // Note that all the values should be string
        pId: pId ? String(pId) : undefined,
        pdfContent: pdfContent ? String(pdfContent) : undefined,
        pSec: pSec ? String(pSec) : undefined,
        r: r ? String(r) : undefined,
        ed: ed ? String(ed) : undefined,
        g: g ? String(g) : undefined,
        d: d ? String(d) : undefined,
        m: m ? String(m) : undefined,
        y: y ? String(y) : undefined,
        zipCode: zipCode ? String(zipCode) : undefined,
        cm: cm ? String(cm) : undefined,
        b: b ? String(b) : undefined,
        et: et ? String(et) : undefined,
        ch: ch ? String(ch) : undefined,
        patientType: patientType ? String(patientType) : undefined,
        ac: ac ? String(ac) : undefined,
        lastQuestion: lastQuestion ? String(lastQuestion) : undefined,
        condition: condition ? String(condition) : undefined,
      },
    },
    tenantKey,
    enrollmentId,
    cohortAdditionalParameters,
  };
};

export { transformDataToSaveMain, transformDataToSaveOutput };
