import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { StNextImage } from '../../components/shared/NextImage.styles';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

export const OptionIconButton: React.FC<{
  tooltipText: string;
  iconImage: ImageField;
  clickHandler?: (e?: any) => void;
}> = ({ tooltipText, iconImage, clickHandler }) => {
  const theme = useTheme();
  return (
    <Tooltip title={tooltipText} placement="top" arrow>
      <IconButton onClick={clickHandler}>
        <StNextImage
          suppressHydrationWarning
          field={iconImage}
          width={20}
          height={25}
          overrideIconColor={`${theme.palette.primary.main}`}
        />
      </IconButton>
    </Tooltip>
  );
};
