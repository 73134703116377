/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {
  ImageField,
  RichText,
  RichTextField,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StDTPGridWithoutBackground, StDTPWrapper } from '../shared/Container/Container.styles';
import { StNextImage } from '../shared/NextImage.styles';
import { PreScreenerFooter } from './PreScreenerBanner.style';
import {
  PreScreenerBannerFields,
  PreScreenerBannerProps,
  PreScreenerFooterItemProps,
  PreScreenerFooterProps,
} from './PreScreenerBanner.types';
import { JOURNEYS } from '../../constants';
import { RootState } from '../../store/store';

const fallbackIcon: ImageField = {
  value: {
    src: '',
  },
};
const fallbackTextField: TextField = {
  value: '',
};
const fallbackRT: RichTextField = {
  value: '',
};
const initialProps: PreScreenerBannerFields = {
  heading: fallbackTextField,
  headingIcon: fallbackIcon,
  subHeading: fallbackRT,
  contentList: [{ fields: { content: fallbackRT, icon: fallbackIcon } }],
  applyGradient: {
    value: false,
  },
};

const FooterInfoItem: React.FC<PreScreenerFooterItemProps> = ({ icon, text }) => {
  return (
    <Box display={'flex'} columnGap={'20px'}>
      {icon?.value?.src !== '' ? (
        <Box flexShrink={0}>
          <StNextImage suppressHydrationWarning field={icon} height={40} width={40} />
        </Box>
      ) : null}

      {text?.value !== '' ? <RichText field={text} /> : null}
    </Box>
  );
};
const Footer: React.FC<PreScreenerFooterProps> = ({ contentList }) => {
  if (Array.isArray(contentList) && contentList.length > 0) {
    return (
      <PreScreenerFooter>
        {contentList.map((item, index) => {
          return (
            <FooterInfoItem key={index} icon={item.fields?.icon} text={item.fields?.content} />
          );
        })}
      </PreScreenerFooter>
    );
  }
  return null;
};
const PrescreenerFooterBanner: React.FC<PreScreenerBannerProps> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  // @ts-ignore
  const isContactInfoPage = sitecoreContext?.route?.fields?.isContactInfoPage?.value || false;
  // @ts-ignore
  const isHomePage = sitecoreContext?.route?.fields?.isHomePage?.value || false;
  const journeyType = useSelector((state: RootState) => state.cdpData.journeyType);
  const isJourneyType3: boolean = journeyType === JOURNEYS.JOURNEY_03;
  const siteSettings: any = sitecoreContext?.siteSettings;
  const isScreenerLandingPageEnabled = siteSettings?.enableScreenerLandingPage?.value;
  const contentList = props.fields?.contentList || initialProps.contentList;
  if (!props?.fields) return <></>;
  if (isContactInfoPage && !isJourneyType3) return <></>;
  if (isScreenerLandingPageEnabled && isHomePage) return <></>;
  return (
    <StDTPWrapper sx={{ marginBottom: '3rem' }} id="pre-screener-footer-banner">
      <StDTPGridWithoutBackground>
        <Footer contentList={contentList} />
      </StDTPGridWithoutBackground>
    </StDTPWrapper>
  );
};

export default PrescreenerFooterBanner;
