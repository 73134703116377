/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useTheme, SxProps } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { getStudyLocation } from './helper';
import SeachZipcode from './SeachZipcode';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderForStudyLocaton, setStudyLocations } from 'src/features/studyLocationSlice';
import VariationOne from './VariationOne/VariationOne';
import VariationTwo from './VariationTwo/VariationTwo';
import ProtocolDropdown from './ProtocolDropdown';
import { StDTPWrapper } from '../../components/shared/Container/Container.styles';
import { StDivider } from './StudyLocation.style';
import CustomNextImage from '../../components/shared/CustomNextImage/CustomNextImage';
// import { useLoadScript } from '@react-google-maps/api';
import { STUDY_LOCATION_VARIATIONS } from 'src/constants';
import { RootState } from '../../../src/store/store';

const StudyLocation = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { sitecoreContext } = useSitecoreContext();
  const isLoaded = useSelector((state: RootState) => state.geolocation?.googleScriptLoaded);
  const siteSettings: any = sitecoreContext?.siteSettings;
  // const [libraries] = useState<
  //   ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]
  // >(['places']);
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: siteSettings?.googleMapsAPIKey?.value,
  //   libraries: libraries,
  //   language: sitecoreContext?.language || 'en',
  // });
  const dispatch = useDispatch();
  const siteDisplayFormatListView = props?.fields?.siteDisplayFormatListView;
  const siteInfoDisplayFormat = props?.fields?.siteInfoDisplayFormat;
  const siteInfoDisplayFormatClick = props?.fields?.siteInfoDisplayFormatClick;
  const siteInfoDisplayFormatHover = props?.fields?.siteInfoDisplayFormatHover;
  const searchButtonText = props?.fields?.searchButtonText;
  const placeholderText = props?.fields?.placeholderText;
  const listText = props?.fields?.listText;
  const mapText = props?.fields?.mapText;
  const subtitle = props?.fields?.subtitle;
  const enrollSoonIcon = props?.fields?.enrollSoonIcon;
  const enrollingMapIcon = props?.fields?.enrollingMapIcon;
  const notEnrollingMapIcon = props?.fields?.notEnrollingMapIcon;
  const enableMapRefresh = siteSettings?.enableMapRefresh?.value || false;
  const positionDropDownInsideList: boolean =
    (props?.fields?.positionDropDownInsideList?.value as boolean) || false;
  const enrollSoonText = props?.fields?.enrollSoonText;
  const enrollSoonHovertext = props?.fields?.enrollSoonHovertext;
  const zipCodeRegex = props?.fields?.zipCodeRegex?.fields?.value;
  const getStudyList = async () => {
    dispatch(setLoaderForStudyLocaton(true));
    const queryParams = {
      SiteRootPath: sitecoreContext?.siteRootPath,
      SiteSettingsId: sitecoreContext?.siteSettingsId,
      Language: sitecoreContext?.language,
      IsSiteDetailsForHomePage: true,
    };
    try {
      const data = await getStudyLocation(queryParams);
      const filterData = props?.fields?.showEnrollSoonSites?.value
        ? data
        : data.filter((item: any) => !item.showEnrollSoon);

      dispatch(setStudyLocations(filterData));
      dispatch(setLoaderForStudyLocaton(false));
    } catch (error) {
      dispatch(setLoaderForStudyLocaton(false));
    }
  };
  useEffect(() => {
    if (
      sitecoreContext?.siteRootPath &&
      sitecoreContext?.siteSettingsId &&
      !isEditorActive() &&
      sitecoreContext?.pageState !== 'preview'
    ) {
      getStudyList();
    }
  }, [sitecoreContext]);

  const currentVariation: string | undefined = props?.fields?.variation?.fields?.key?.value;
  const renderZipcodeSection = () => {
    if (
      props?.fields?.variation?.fields?.key?.value === STUDY_LOCATION_VARIATIONS.VARIATION_ONE ||
      props?.fields?.variation?.fields?.key?.value === STUDY_LOCATION_VARIATIONS.VARIATION_TWO ||
      (props?.fields?.variation?.fields?.key?.value === STUDY_LOCATION_VARIATIONS.VARIATION_THREE &&
        !isDesktop)
    ) {
      return (
        <Box paddingTop={'1rem'}>
          <Typography
            variant="body1"
            style={{
              color: '#444',
              textAlign: 'left',
              fontFamily: 'Proxima Nova',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
            }}
          >
            <Text field={props?.fields?.subtitle} />
          </Typography>

          <Stack direction={isMobile ? 'column' : 'row'} spacing={2} marginTop={'0.5rem'}>
            <SeachZipcode
              searchButtonText={searchButtonText}
              subtitle={subtitle}
              placeholderText={placeholderText}
              showEnrollSoonSites={props?.fields?.showEnrollSoonSites}
              zipCodeRegex={zipCodeRegex}
            />
          </Stack>
        </Box>
      );
    } else {
      return null;
    }
  };

  /**
   * Its always displayed outside list in Tab and Mobile regardless of `positionDropDownInsideList` value.
   * Variation1 doesnt have a List view so its always displayed regardless of `positionDropDownInsideList` value.
   */
  const isShowFilterOutsideList = () => {
    if (!isDesktop || currentVariation === 'Variation1') {
      return true;
    }
    if (positionDropDownInsideList) {
      return false;
    }
    return true;
  };

  const dropdownStyleDesktop: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
    width: '380px',
    marginBlock: '1rem !important',
    [theme.breakpoints.down('md')]: {
      marginBlock: '1rem',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
  if (!props?.fields) return <></>;

  return (
    isLoaded && (
      <>
        <StDTPWrapper id={'study-location'}>
          <Stack alignItems="center" spacing={0} sx={{ paddingInline: !isDesktop ? '24px' : '' }}>
            <CustomNextImage
              desktopImg={props?.fields?.iconDesktop}
              tabletImg={props?.fields?.iconTablet}
              mobileImg={props?.fields?.iconMobile}
            />
            <Typography
              variant="body1"
              style={{
                color: theme.palette.themeColor.main,
                textAlign: 'center',
                fontFamily: 'Proxima Nova',
                fontSize: '32px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '40px',
                marginBlock: '1rem',
              }}
            >
              <Text field={props?.fields?.title} />
            </Typography>
            <StDivider />
            {renderZipcodeSection()}
            {enableMapRefresh && isShowFilterOutsideList() && (
              <ProtocolDropdown
                enableViewAllSites={props.fields?.enableViewAllSites}
                makeDropdownReadOnly={props.fields?.makeDropdownReadOnly}
                viewAllSitesText={props.fields?.viewAllSitesText}
                viewSitesForLabelText={props.fields?.viewSitesForLabelText}
                positionDropDownInsideList={positionDropDownInsideList}
                style={dropdownStyleDesktop}
              />
            )}
          </Stack>
          <Typography
            variant="body1"
            style={{
              color: '#444',
              textAlign: 'center',
              fontFamily: 'Proxima Nova',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              marginTop: '1rem',
            }}
          >
            <Text field={props?.fields?.description} />
          </Typography>

          <Grid
            container
            direction="column"
            sx={{
              alignContent: 'center',
              margin: 'auto',
              marginBlock: '1rem',
            }}
            gap={1}
            id="study-location"
          >
            <>
              {currentVariation === 'Variation1' ? (
                <VariationOne
                  variation={currentVariation}
                  siteInfoDisplayFormatHover={siteInfoDisplayFormatHover}
                  buttonText={props?.fields?.callStudyCenter}
                  enrollingMapIcon={enrollingMapIcon}
                  enrollSoonIcon={enrollSoonIcon}
                  notEnrollingMapIcon={notEnrollingMapIcon}
                  enrollSoonText={enrollSoonText}
                  enrollSoonHovertext={enrollSoonHovertext}
                />
              ) : (
                <VariationTwo
                  variation={currentVariation}
                  buttonText={props?.fields?.callStudyCenter}
                  enrollingMapIcon={enrollingMapIcon}
                  notEnrollingMapIcon={notEnrollingMapIcon}
                  enrollSoonIcon={enrollSoonIcon}
                  noSitesAvailableText={props?.fields?.noSitesAvailableText}
                  siteInfoDisplayFormatHover={siteInfoDisplayFormatHover}
                  siteInfoDisplayFormat={siteInfoDisplayFormat}
                  siteInfoDisplayFormatClick={siteInfoDisplayFormatClick}
                  siteDisplayFormatListView={siteDisplayFormatListView}
                  listText={listText}
                  mapText={mapText}
                  searchButtonText={searchButtonText}
                  subtitle={subtitle}
                  placeholderText={placeholderText}
                  showEnrollSoonSites={props?.fields?.showEnrollSoonSites}
                  enrollSoonText={enrollSoonText}
                  enrollSoonHovertext={enrollSoonHovertext}
                  // props for filter dropdown
                  enableViewAllSites={props.fields?.enableViewAllSites}
                  makeDropdownReadOnly={props.fields?.makeDropdownReadOnly}
                  viewAllSitesText={props.fields?.viewAllSitesText}
                  viewSitesForLabelText={props.fields?.viewSitesForLabelText}
                  positionDropDownInsideList={positionDropDownInsideList}
                  zipCodeRegex={zipCodeRegex}
                />
              )}
            </>
          </Grid>
        </StDTPWrapper>
      </>
    )
  );
};

export default StudyLocation;
