import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import Video from '../Video/Video';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ComponentProps } from 'lib/component-props';
import CustomNextImage from '../CustomNextImage/CustomNextImage';
import SuccessPageMergeTag from '../../SitesSuccessPage/SuccessPageMergeTag/SuccessPageMergeTag';
import SuccessPageMergeTagText from '../../SitesSuccessPage/SuccessPageMergeTag/SuccessPageMergeTagText';

type TextDetails = ComponentProps & {
  templateName: string;
  fields: {
    heading: Field<string>;
    content: Field<string>;
  };
};

type ImageDetails = TextDetails & {
  fields: {
    imageContent?: ImageField;
    imageContentMobile: ImageField;
    imageContentTablet: ImageField;
    imageContentDesktop: ImageField;
  };
};

type VideoDetails = TextDetails & {
  fields: {
    videoUrl?: Field<string>;
    isAutoplay?: Field<boolean>;
    enableMute?: Field<boolean>;
    mobileHeight?: Field<string>;
    tabletHeight?: Field<string>;
    desktopHeight?: Field<string>;
  };
};

type SingleColumn = {
  isSingleColumn: boolean;
};

type SiteInfo = {
  mergeTagData?: any;
};

type OneColumnProps = (TextDetails | ImageDetails | VideoDetails) & SingleColumn & SiteInfo;

const OneColumnGrid = (props: OneColumnProps): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  if (!props?.fields) return <></>;
  const contentType = props?.templateName;
  const imageObjectFields = props as ImageDetails;
  return (
    <Grid container item id="text-container">
      {contentType === 'TextContainer' && (
        <Grid container item gap={1}>
          <Typography variant="btnText" sx={{ fontSize: '1.5rem' }}>
            <SuccessPageMergeTagText
              mergeTagField={props?.fields?.heading}
              mergeTagObj={props?.mergeTagData}
            />
          </Typography>
          <Typography variant="body" textAlign="left" color={'#000000'}>
            <SuccessPageMergeTag
              mergeTagField={props?.fields?.content}
              mergeTagObj={props?.mergeTagData}
            />
          </Typography>
        </Grid>
      )}
      {contentType === 'VideoContainer' && (
        <Grid
          id="video-container"
          container
          item
          gap={1}
          alignItems="center"
          justifyContent="center"
          flexDirection={matches && !props?.isSingleColumn ? 'column-reverse' : 'column'}
        >
          <Box sx={{ width: '100%' }}>
            <Video fields={props as VideoDetails}></Video>
          </Box>
          <Box>
            <Typography variant="btnText">
              <SuccessPageMergeTagText
                mergeTagField={props?.fields?.heading}
                mergeTagObj={props?.mergeTagData}
              />
            </Typography>
            <Typography variant="body" textAlign="justify">
              <SuccessPageMergeTag
                mergeTagField={props?.fields?.content}
                mergeTagObj={props?.mergeTagData}
              />
            </Typography>
          </Box>
        </Grid>
      )}
      {contentType === 'ImageContainer' && (
        <Grid
          id="image-container"
          container
          item
          gap={1}
          alignItems="center"
          justifyContent="center"
          flexDirection={matches && !props?.isSingleColumn ? 'column-reverse' : 'column'}
          sx={{ overflow: 'hidden', width: '100%' }}
        >
          <CustomNextImage
            mobileImg={imageObjectFields?.fields?.imageContentMobile}
            tabletImg={imageObjectFields?.fields?.imageContentTablet}
            desktopImg={imageObjectFields?.fields?.imageContentDesktop}
          />
          <Box>
            <Typography variant="btnText">
              <SuccessPageMergeTagText
                mergeTagField={props?.fields?.heading}
                mergeTagObj={props?.mergeTagData}
              />
            </Typography>
            <Typography variant="body" textAlign="justify">
              <SuccessPageMergeTag
                mergeTagField={props?.fields?.content}
                mergeTagObj={props?.mergeTagData}
              />
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default OneColumnGrid;
