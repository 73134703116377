import getGraphQLClient from '../../../src/GraphQL/graphQLClient';
import breadcrumbsQuery, { breadcrumbsQueryParams } from 'src/GraphQL/queries/breadcrumbs.query';
import { GetStaticComponentProps, constants } from '@sitecore-jss/sitecore-jss-nextjs';

const getBreadcrumbsStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  try {
    if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
      return null;
    }
    const language =
      layoutData?.sitecore?.context?.language || process.env.DEFAULT_LANGUAGE || 'en';
    const itemId = layoutData?.sitecore?.route?.itemId || '';
    if (!itemId) {
      return { breadcrumbsData: null };
    }
    const graphQLClient = getGraphQLClient();
    const breadcrumbsData = await graphQLClient.request<any>(
      breadcrumbsQuery as any,
      breadcrumbsQueryParams(language, itemId)
    );
    return { breadcrumbsData };
  } catch (_error) {
    return { breadcrumbsData: null };
  }
};
export default getBreadcrumbsStaticProps;
