import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { JSSLink } from '../../Links/JSSLink.styles';
import {
  StCardDesc,
  StTrialCardSubTitle,
  StCardTitle,
  StCountContainer,
  StCountText,
} from '../Carousel.styles';
import { TrialCardType } from '../Carousel.types';
import { updateLastVisitedComponentId } from '../../../../features/globalConfig';
import { useDispatch } from 'react-redux';

const TrialCard = (props: TrialCardType): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const trials = props?.data?.fields;

  const getCardWidth = () => {
    if (props?.isTablet) {
      return 306;
    } else if (props?.isMobile) {
      return 271;
    } else {
      return 282;
    }
  };

  const getCardMaxWidth = () => {
    if (props?.isTablet) {
      return 306;
    } else if (props?.isMobile) {
      return 271;
    } else {
      return 355;
    }
  };
  const carouselDataIndex = props?.carouselDataIndex ? props.carouselDataIndex : 0;
  const onClickHandler = () => {
    if (props?.id) {
      dispatch(updateLastVisitedComponentId(props?.id));
    }
  };
  return (
    <Card
      sx={{
        minWidth: getCardWidth(),
        maxWidth: getCardMaxWidth(),
        marginInline: props?.isTablet || props?.isMobile ? 'none' : 'auto',
        margin: props?.isTablet || props?.isMobile ? '1.4rem' : 'none',
        borderRadius: '0.6rem',
        boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%', height: 'auto' }}>
          <NextImage
            field={trials?.relatedTrial?.fields?.featuredTrialImage}
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      </Box>

      {props.showCount && (
        <StCountContainer data-testid={`showCount-${props.itemIndex}`}>
          <StCountText>
            {props?.isTablet || props?.isMobile
              ? props.itemIndex + 1
              : props.itemIndex + 1 + carouselDataIndex * 3}
            /{props.totalCount}
          </StCountText>
        </StCountContainer>
      )}

      <CardContent sx={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
        <StCardTitle>
          <Text field={trials?.relatedTrial?.fields?.featuredTrialCondition?.fields?.description} />
        </StCardTitle>
        <StTrialCardSubTitle limit={props?.titleLineLimit}>
          <Text field={trials?.relatedTrial?.fields?.trialTitle} />
        </StTrialCardSubTitle>
        <StCardDesc limit={props?.descriptionLineLimit}>
          <Text field={trials?.relatedTrial?.fields?.trialDescription} />
        </StCardDesc>
        {/* <JSSLink variant="outlined" sx={{ width: '100%' }} field={trials?.trialCTA} /> */}
      </CardContent>
      <CardActions sx={{ margin: '1rem', padding: '0px' }} onClick={() => onClickHandler()}>
        <JSSLink
          className={'trial-card-btn btn'}
          variant="outlined"
          sx={{ width: '100%' }}
          field={trials?.trialCTA}
        />
      </CardActions>
    </Card>
  );
};

export default TrialCard;
