import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useEffect, useState } from 'react';

function useStickyScroll(route: any, enableAutoShowMobile: boolean) {
  const [scrolling, setScrolling] = useState(false);
  const [scrollThreshold, setScrollThreshold] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(510));

  useEffect(() => {
    if (isEditorActive()) {
      setScrolling(false);
      return;
    }
    if (enableAutoShowMobile && isMobile) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }

    function onScroll() {
      const eleherobanner = document?.getElementById('hero-banner') as HTMLElement;
      const elefooter = document?.getElementById('footer') as HTMLElement;
      const elepreScreener = document?.getElementById('pre-screener-wrapper') as HTMLElement;
      const iframeElement = document?.getElementById(
        'pre-screener-questionnaire-iframe'
      ) as HTMLElement;
      const contactInfoQuesEle = document?.getElementById(
        'contact-info-questionare'
      ) as HTMLElement;
      const elepreScreenerFooter = document?.getElementById(
        'pre-screener-footer-banner'
      ) as HTMLElement;
      const eleStudyLocation = document?.getElementById('study-location') as HTMLElement;

      const currentPositionFromTop = window.pageYOffset;
      const currentPosition = window.pageYOffset + window.innerHeight;
      const heroBannerEnd = eleherobanner?.offsetTop + eleherobanner?.offsetHeight;
      const footerStart = elefooter.offsetTop;
      const preSreenerStart = elepreScreener?.offsetHeight + elepreScreener?.offsetTop;
      const preSreenerEnd =
        elepreScreener?.offsetHeight + elepreScreener?.offsetTop + window.innerHeight;

      const studyLocationStart = eleStudyLocation?.offsetTop;
      const studyLocationEnd =
        eleStudyLocation?.offsetTop + eleStudyLocation?.offsetHeight + window.innerHeight;

      if (enableAutoShowMobile && isMobile) {
        if (
          (currentPosition > preSreenerStart &&
            currentPosition <
              preSreenerEnd + iframeElement?.offsetHeight + elepreScreenerFooter?.offsetHeight) ||
          currentPosition > footerStart
        ) {
          setScrolling(false);
        } else if (
          (currentPosition > preSreenerStart &&
            currentPosition <
              preSreenerEnd +
                contactInfoQuesEle?.offsetHeight +
                elepreScreenerFooter?.offsetHeight) ||
          currentPosition > footerStart
        ) {
          setScrolling(false);
        } else if (
          (currentPosition > studyLocationStart && currentPosition < studyLocationEnd) ||
          currentPosition > footerStart
        ) {
          setScrolling(false);
        } else {
          setScrolling(true);
        }
      } else {
        if (currentPositionFromTop < heroBannerEnd) {
          setScrolling(false);
        } else if (
          (currentPosition > preSreenerStart &&
            currentPosition <
              preSreenerEnd + iframeElement?.offsetHeight + elepreScreenerFooter?.offsetHeight) ||
          currentPosition > footerStart
        ) {
          setScrolling(false);
        } else if (
          (currentPosition > preSreenerStart &&
            currentPosition <
              preSreenerEnd +
                contactInfoQuesEle?.offsetHeight +
                elepreScreenerFooter?.offsetHeight) ||
          currentPosition > footerStart
        ) {
          setScrolling(false);
        } else if (
          (currentPosition > studyLocationStart && currentPosition < studyLocationEnd) ||
          currentPosition > footerStart
        ) {
          setScrolling(false);
        } else {
          if (eleherobanner) {
            setScrolling(true);
          }
        }
      }

      function calculateScrollPercentage() {
        const scrollTop = window.scrollY;
        const pageHeight = document.documentElement.scrollHeight - window.innerHeight;

        const scrollPercentage = (scrollTop / pageHeight) * 100;
        return scrollPercentage;
      }

      const scrollPercent = calculateScrollPercentage();

      if (scrollPercent > 60) {
        setScrollThreshold(true);
      } else {
        setScrollThreshold(false);
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [route?.asPath, isMobile]);

  return { scrolling, scrollThreshold };
}

export default useStickyScroll;
