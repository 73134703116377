import { useEffect } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { post } from '../../lib/utils/axiosFetcher';
import { updateQueryParamList } from '../../features/configSlice';
import { pickBy } from 'lodash';
import { getQuery } from '../../lib/utils/common';

const addOrUpdateQueryParams = async (
  queryParams: any,
  enrollmentId: string,
  tenantKey: string,
  potentialId: string
) => {
  const url = '/Participant/AddOrUpdateQueryParams';
  const data = {
    queryParams: queryParams,
    enrollmentId: enrollmentId,
    tenantKey: tenantKey,
    potentialId: potentialId,
  };
  try {
    await post(url, data);
  } catch (_e) {}
};

const useQueryParam = () => {
  const router: NextRouter = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const queryParams = useSelector((state: RootState) => state.config.queryParamList);
  const potentialParticipant = useSelector((state: RootState) => state.potentialParticipantDetails);
  const { enrollmentId, tenantKey, potentialId } = potentialParticipant;
  const retainQueryParamList = siteSettings?.RetainQueryParamList?.value?.split('&');
  const removeQueryParamList = siteSettings?.RemoveQueryParamList?.value?.split('&');
  const isHomePage = (sitecoreContext?.route?.fields?.isHomePage as any)?.value || false;
  const searchQueryData = useSelector((state: RootState) => state?.queryParams);
  const isInfoEpr = (sitecoreContext?.route?.fields?.isInfoEpr as any)?.value || false;

  const dispatch = useDispatch();
  const { query } = router;
  useEffect(() => {
    const params = { ...searchQueryData };
    const result = pickBy(
      getQuery(params, retainQueryParamList, removeQueryParamList),
      (value) => !!value?.length
    );
    if (Object.keys(result).length == 0) {
      retainQueryParamList?.forEach((key: string) => {
        if (query.hasOwnProperty(key) && !removeQueryParamList?.hasOwnProperty(key)) {
          result[key] = query[key];
        }
      });
    }
    if (retainQueryParamList && Object.keys(retainQueryParamList).length > 0) {
      let atid;
      if (
        !query.hasOwnProperty('atid') &&
        siteSettings?.aTIdDefaultValue?.value !== undefined &&
        siteSettings?.aTIdDefaultValue?.value !== ''
      ) {
        atid = siteSettings?.aTIdDefaultValue?.value;
        result['atid'] = atid;
      }
      const paramsList = Object.entries(result).map(([key, value]) => ({
        queryParamKey: key,
        queryParamValue: value,
      }));
      dispatch(updateQueryParamList(paramsList));
    }
  }, [query]);

  // useEffect(() => {
  //   let atid;
  //   if (query.hasOwnProperty('atid') && query['atid'] !== '') {
  //     atid = query['atid'];
  //   } else if (siteSettings?.aTIdDefaultValue?.value !== '') {
  //     atid = siteSettings?.aTIdDefaultValue?.value;
  //   }

  //   if (atid !== '' && enrollmentId && potentialId) {
  //     const params = [{ queryParamKey: 'atid', queryParamValue: atid }];
  //     addOrUpdateQueryParams(params, enrollmentId, tenantKey, potentialId);
  //   }
  // }, [query, enrollmentId, potentialId]);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      if (enrollmentId && potentialId && isHomePage && !isInfoEpr) {
        addOrUpdateQueryParams(queryParams, enrollmentId, tenantKey, potentialId);
      }
    }
  }, [enrollmentId, potentialId, isHomePage]);
};

export default useQueryParam;
