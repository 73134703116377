import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect } from 'react';
import { JSSLink } from '../shared/Links/JSSLink.styles';
import { StNextImage } from '../shared/NextImage.styles';
import StErrorPageContainer from './ErrorPage.style';

const ErrorPage = (props: any): any => {
  useEffect(() => {
    const pageContainerElement = props.pageContainerRef?.current;
    if (pageContainerElement) {
      pageContainerElement.style.minHeight = 'calc(70vh - 7rem)';
    }
  }, [props.pageContainerRef]);

  if (!props?.fields) return <></>;

  return (
    <StErrorPageContainer sx={{ minHeight: props?.isFooterExist ? '655px' : '100vh' }}>
      <Box className="content">
        <Stack spacing={5}>
          <Box display="flex" justifyContent="center">
            <StNextImage
              suppressHydrationWarning
              data-testid="icon"
              field={props.fields.icon}
              width={97}
              height={87}
            />
          </Box>

          <Box
            sx={{
              fontFamily: `Proxima Nova`,
              fontWeight: 600,
              fontSize: '1rem',
              lineHeight: '20px',
              textAlign: 'center',
            }}
          >
            <RichText field={props.fields.content} />
          </Box>

          <Grid className="sub-content">
            <Text field={props.fields.subContent} />
          </Grid>

          <Box className="back-btn">
            <JSSLink
              field={props?.fields?.link}
              variant="outlined"
              className={'back-link btn'}
              aria-label={props?.fields?.ariaLabel?.value}
            />
          </Box>
        </Stack>
      </Box>
    </StErrorPageContainer>
  );
};

export default ErrorPage;
