import { useForm } from 'react-hook-form';
import { ComponentProps } from 'lib/component-props';
import { GetStaticComponentProps, constants } from '@sitecore-jss/sitecore-jss-nextjs';
import { getAllStates } from 'src/GraphQL/queries/stateList.query';
import { getAllTimeZones } from 'src/GraphQL/queries/timeZoneList.query';
import { debounce } from '@mui/material/utils';
import ContactInfoContextProvider from '../ContactInfoContextProvider';
import { ContactInfoFieldsInterface } from '../ContactInfo.types';
import { useStoreContactInfoData } from '../useStoreContactInfoData';
import { formatCaregiverFormData, formatDOB } from '../../../transformers/contactInfo.transform';
import { updateContactInfo } from 'src/features/contactInfoSlice';
import useContactInfoHelper from 'src/lib/hooks/useContactInfoHelper';
import {
  constructVirtualSiteData,
  prePopulateSceeenerDataCaregiverForm,
  updateConsents,
  validateSMSandIsMobile,
  prepopulateMyProfileForCaregiverForm,
  constructCOIBasedOnKey,
  constructConditionsOfInterest,
} from '../helper';
import { getDefaultTimeZone } from '../../../lib/utils/timeZoneHelpers';
import { useRouter } from 'next/router';
import { ExtenedLayoutServiceContextInterface } from '../../../lib/type/Site';
import { useEffect, useRef, useState } from 'react';
import * as cdpService from '../../../services/CdpService';
import { CDP_EVENTS, JOURNEYS, PATIENT_TYPE_ADULT } from '../../../constants';
import {
  fetchProfileDetailsForCR,
  prePopulateEprContactDetails,
  savePatientDelegateDetailsToCDP,
} from '../../../lib/utils/axiosCDPFetcher';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookie,
  getCookieExpiryTime,
  getOrCreateEnrollmentId,
  getEnrollmentId,
} from '../../../lib/utils/enrollmentUtil';
import { JOURNEY_STEP } from '../../../constants';
import { fetchNextPossiblePathAndData } from '../../../services/CdpService';
import { storeCDPData } from '../../../features/cdpDataSlice';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import getBaseURL from '../../../lib/utils/getBaseURL';
import { LifeLinkRequest } from '../../../types/LifeLink';
import { updateLLDetailsTODB, postToLifeLink, post } from '../../../lib/utils/axiosFetcher';
import getQueryParamsAsString from '../../../lib/utils/getQueryParamsAsString';
import { getQuery, getRespectiveCOIList } from '../../../lib/utils/common';
import { setCookie } from 'cookies-next';
import { checkReturningUser } from '../../../services/ReturningUserService';
import { addpotentialParticipantId } from '../../../features/potentialParticipantDetailsSlice';
import { PatientType } from '../../../lib/type/userJourney.types';
import { useMutation } from 'react-query';
import { trackEvent } from '../../../lib/utils/gtmService';
import { CR_SITE } from '../../../lib/constant';
import isEmpty from 'lodash/isEmpty';
import { fetchConditionOfInterest } from '../../../GraphQL/queries/fetchConditionOfInterest.query';
import { setAuthentication } from '../../../features/authSlice';
import { setProfileData } from '../../../features/crProfileSlice';
import { setSelectedCOIList } from '../../../features/registrationSlice';
import {
  getCommunities,
  getStringValFromArray,
  getStringValFromArrayForCDP,
} from '../../../services/RegisterUserService';
import dynamic from 'next/dynamic';
import axios from 'axios';
import { pickBy } from 'lodash';

const ContactInfo = dynamic(() => import('../ContactInfo'), {
  ssr: false,
});

export type ContactListProps = ComponentProps & ContactInfoFieldsInterface;
const MAX_RETRY_COUNT = 3;
const MAX_EXPERIENCE_RETRY_COUNT = 4;
const CaregiverContactInfo = (props: ContactListProps): JSX.Element => {
  const {
    dispatch,
    executeRecaptcha,
    selectedLanguage,
    potentialParticipant_ID,
    potentialId,
    isRecapchaEnabled,
    timeZoneList,
    screenerOutput,
    prepopulateConfig,
    reCaptchItem,
    autoCompleteLocationInfo,
    geolocation,
    cdpState,
    siteSettingsId,
    setCustomErrorModal,
    showCustomErrorModal,
    setPatientExistModal,
    showPatientExistErrorModal,
    returningUserTimeInterval,
    returningUserTimeValue,
    setEmailSentModal,
    showEmailSentModal,
    ContactNumberField,
    ConsentsMedium,
    getInitialVlaueForIsMobile,
  } = useContactInfoHelper(props);

  const router = useRouter();
  const { query } = router;

  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const tenantKey = siteSettings?.tenantKey?.value || '';
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const cdpData = useSelector((state: RootState) => state?.cdpData);
  const isEnableGoogleRecaptcha = siteSettings?.EnableGoogleRecaptcha?.value || false;
  const selectedPlace = useSelector((state: RootState) => state.selectedSite);
  const sitecoreFieldsForContactInfo = useSelector(
    (state: RootState) => state.contactInfoDetails.sitecoreFieldsForContactInfo
  );
  const selectedCOIList = useSelector(
    (state: RootState) => state.registrationDetails.selectedCOIList
  );
  const formatedCOIList = useSelector(
    (state: RootState) => state.contactInfoDetails.conditionOfIntrestList
  );
  const stepNumber: number = useSelector((state: RootState) => state.contactInfoDetails.stepNumber);

  const siteName = sitecoreContext?.site?.name;
  const updatePpidRef = useRef<any>(false);
  const retuningUser = useRef<any | null>(null);
  const [myProfileData, setMyProfileData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const retryCount = useRef(0);
  const isCurrentResume = useRef<any>(false);
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const searchQueryData = useSelector((state: RootState) => state?.queryParams);
  const searchQueryDataCopy = { ...searchQueryData };
  const [isMounted, setIsMounted] = useState(false);

  const removeQueryParamList = (siteSettings as any)?.RemoveQueryParamList?.value?.split('&');
  const retainQueryParamList = (siteSettings as any)?.RetainQueryParamList?.value?.split('&');

  const queryParamsData = pickBy(
    getQuery(searchQueryDataCopy, retainQueryParamList, removeQueryParamList),
    (value) => !!value?.length
  );
  const getFormDataDefaultValue = (existingValues: any) => {
    if (myProfileData) {
      return {
        UserTimeZone: getDefaultTimeZone(timeZoneList, existingValues),
        isMobile: existingValues?.isMobile ? true : false,
        ...prePopulateSceeenerDataCaregiverForm(
          screenerOutput,
          prepopulateConfig,
          existingValues,
          geolocation,
          sitecoreFieldsForContactInfo,
          cdpData
        ),
        ...prepopulateMyProfileForCaregiverForm(myProfileData),
      };
    } else {
      return {
        UserTimeZone: getDefaultTimeZone(timeZoneList, existingValues),
        isMobile: getInitialVlaueForIsMobile(existingValues),
        ...prePopulateSceeenerDataCaregiverForm(
          screenerOutput,
          prepopulateConfig,
          existingValues,
          geolocation,
          sitecoreFieldsForContactInfo,
          cdpData
        ),
      };
    }
  };

  const updateCurrentJourneyStepinCDP = async () => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: JOURNEY_STEP.CONTACT_INFO,
      journeyStatus: cdpState?.journeyStatus,
      isActiveEnrollment: '1',
    };

    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      cdpService.updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const prepopulateMyProfile = async () => {
    const authenticatedUserToken = sessionStorage.getItem('myProfileVerificationToken');
    if (authenticatedUserToken && isAuthenticated) {
      const response = await prePopulateEprContactDetails(authenticatedUserToken, tenantKey);
      if (response.data) {
        setMyProfileData(response.data);
      }
    }
  };

  const populateConditionOfInterest = async (conditionsList: any) => {
    try {
      const response = constructConditionsOfInterest(conditionsList, formatedCOIList);
      if (response && response.length > 0) {
        dispatch(setSelectedCOIList(response));
      }
    } catch (_e) {
      return;
    }
  };

  const formHandlerObject = useForm({
    mode: 'all',
    defaultValues: async () => getFormDataDefaultValue({}),
  });

  const { watch, trigger, setError } = formHandlerObject;
  const FieldsToCheckForReturningUser = [
    'CaregiverEmail',
    'ParticipantFirstName',
    'ParticipantLastName',
    'Day',
    'Month',
    'Year',
  ];

  useEffect(() => {
    updateCurrentJourneyStepinCDP();
    prepopulateMyProfile();
    trackEvent(
      {
        event: 'Participant Type identified',
        participantType: PatientType.CAREGIVER,
      },
      siteName === CR_SITE ? true : false
    );
  }, []);

  useEffect(() => {
    const existingValues = formHandlerObject?.getValues();
    formHandlerObject.reset({ ...existingValues, ...getFormDataDefaultValue(existingValues) });
  }, [prepopulateConfig, sitecoreFieldsForContactInfo, myProfileData]);

  useEffect(() => {
    const debouncedCb = debounce(async (value: any, { name }) => {
      if (FieldsToCheckForReturningUser.includes(name)) {
        const allValuesAvailable = FieldsToCheckForReturningUser.every((field: string) => {
          if (name === 'Day' || name === 'Month' || name === 'Year') {
            return (
              (value['Year'] !== '' && value['Year'] !== null && value['Year'] !== undefined) ||
              (value['Month'] !== '' && value['Month'] !== null && value['Month'] !== undefined) ||
              (value['Day'] !== '' && value['Day'] !== null && value['Day'] !== undefined)
            );
          } else {
            return value[field] !== '' && value[field] !== null && value[field] !== undefined;
          }
        });
        if (allValuesAvailable !== undefined && allValuesAvailable) {
          const isValid = await trigger(FieldsToCheckForReturningUser as never[]);
          if (isValid) {
            await populateExistingUserConsents(
              value['CaregiverEmail'],
              value['ParticipantFirstName'],
              value['ParticipantLastName'],
              value['Day'],
              value['Month'],
              value['Year']
            );
          }
        }
      }
    }, 3000);

    const subscription = watch((v, n) => {
      debouncedCb(v, n);
      if (ConsentsMedium.length > 0) {
        validateSMSandIsMobile(v, trigger, setError, ContactNumberField);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {
    if (screenerOutput?.condition && siteSettings?.source?.fields?.name?.value === 'CR') {
      const conditionKeyDescription = screenerOutput?.condition?.split(',');
      if (conditionKeyDescription?.length > 0) {
        const screenerCondition = constructCOIBasedOnKey(conditionKeyDescription, formatedCOIList);
        populateConditionOfInterest(screenerCondition);
      }
    }
  }, [stepNumber, isMounted]);

  const updatePotentialParticipantId = async () => {
    try {
      const { data: response } = await post(
        `/Participant/UpdatePotentialParticipantId?potentialId=${potentialId}&potentialParticipantId=${getCookie(
          'bx_guest_ref'
        )}`
      );
      return response;
    } catch (_e: any) {}
  };

  const { mutate: updatePpId } = useMutation(
    'update-potential-participantId',
    updatePotentialParticipantId,
    {
      onSuccess: (_data) => {
        return _data;
      },
      onError: () => undefined,
    }
  );

  const successCallbackRetry = async ({ data }: any) => {
    const extensionData = {
      ContactFormSubmit: true,
    };
    const { getValues } = formHandlerObject;
    const email = getValues('CaregiverEmail' as never);
    cdpService.identityEvent(email);

    cdpService.event(CDP_EVENTS.CONTACT_FORM_SUBMIT, extensionData);
    dispatch(updateContactInfo(data));

    const successCallbackData = data;
    try {
      if (updatePpidRef.current) {
        updatePpId();
      }
      await fetchProfileDataSeparateThread();
      //Exp api call to get next possible path
      try {
        const data = await fetchNextSteps();
        if (journeyType === JOURNEYS.JOURNEY_02 && data?.isVirtual) {
          await cdpService.updateIsVirtualinCDP(
            getCookie('bx_guest_ref'),
            cdpState?.guestType || 'visitor',
            data.isHumanAPIEnabledForVirtual,
            data?.sitesData,
            potentialId,
            potentialParticipant_ID,
            successCallbackData.patientId
          );

          const constructedSitesData = constructVirtualSiteData(
            data,
            tenantKey,
            getOrCreateEnrollmentId(),
            potentialId,
            potentialParticipant_ID,
            successCallbackData
          );

          const response = await post(
            '/SiteSelection/AddOrUpdatePatientSiteDetails',
            constructedSitesData
          );
          if (!response) {
            throw new Error('Error in updating Virtual Sites Data');
          }
        }

        const getPostToLLPayload = (): LifeLinkRequest => {
          const siteSettingsId: any = sitecoreContext?.siteSettingsId;
          const language: any = sitecoreContext?.language || 'en';
          return {
            tenantKey: siteSettings?.tenantKey?.value || '',
            enrollmentId: getOrCreateEnrollmentId(),
            lifeLinkEnabled: '1',
            subjectHighRisk: screenerOutput.r || '',
            baseURL: getBaseURL(),
            queryParams: getQueryParamsAsString(),
            sitecode: selectedPlace?.siteInfo?.siteCode || '',
            protocol: selectedPlace?.siteInfo?.protocol || '',
            languageName: language,
            siteSettingsId: siteSettingsId || '',
            isLLBackgroundJobCall: false,
            PotentialId: potentialId,
          };
        };

        const goToNextStep = async () => {
          let appointmentStatus = data?.appointmentStatus;
          if (!appointmentStatus) {
            appointmentStatus = 'NotInitiated';
          }
          if (journeyType === JOURNEYS.JOURNEY_01 && selectedPlace?.siteInfo?.isLifeLinkEnabled) {
            if (!data?.isPostTOLL) {
              const patientId = successCallbackData.patientId || '';
              await updateLLDetailsTODB(
                getOrCreateEnrollmentId(),
                tenantKey,
                appointmentStatus,
                potentialId,
                patientId
              );
            }
          }
          if (data?.nextStepUrl) {
            setCookie('journeyStep', JOURNEY_STEP.CONTACT_INFO, {
              expires: getCookieExpiryTime(
                siteSettings?.resumeEnrollmentExpirationDays?.value ||
                  ENROLLMENT_EXPIRY_TIME_FALLBACK
              ),
              secure: true,
            });
            trackEvent(
              {
                event: 'Completed Contact Form',
              },
              siteName === CR_SITE ? true : false
            );
            await router.replace(
              {
                pathname: data?.nextStepUrl,
                query: getQuery(query, retainQueryParamList, removeQueryParamList),
              },
              undefined
            );
          }
        };
        dispatch(storeCDPData(data));
        if (data?.isPostTOLL) {
          const payload = getPostToLLPayload();
          try {
            const dapApiLLResponse = await postToLifeLink(payload);

            if (dapApiLLResponse.lifeLinkUrl) {
              sessionStorage.setItem('isLLRedirection', 'true');
              window.location.href = dapApiLLResponse.lifeLinkUrl;
            } else {
              if (data?.nextStepUrl) {
                await goToNextStep();
              }
            }
          } catch (e: any) {
            if (data?.nextStepUrl) {
              await goToNextStep();
            }
          }
        } else if (data?.nextStepUrl) {
          await goToNextStep();
        }
      } catch (_e) {
        throw new Error('EXP API Failed');
      }
    } catch (_e: any) {
      throw new _e();
    }
  };
  const successCallback = async ({ data }: any) => {
    setIsLoading(true);
    try {
      await successCallbackRetry(data);
    } catch (e: any) {}
    setIsLoading(false);
  };

  const fetchProfileDataSeparateThread = async () => {
    setTimeout(async () => {
      const myProfileVerificationToken = sessionStorage.getItem('myProfileVerificationToken');

      if (myProfileVerificationToken) {
        try {
          const { data } = await fetchProfileDetailsForCR(myProfileVerificationToken);
          if (data) {
            dispatch(setProfileData(data));
          }
        } catch (_e) {
          throw new Error('Fetch profile details failed');
        }
      }
    }, 500);
  };

  const savedataToCdp = async (cdpPayload: any) => {
    while (retryCount.current < MAX_RETRY_COUNT) {
      try {
        retryCount.current += 1;
        const data = await savePatientDelegateDetailsToCDP(
          `/api/contacts/AddUpdatePatientDetails/${getCookie('bx_guest_ref')}?guestType=${
            cdpState?.guestType || 'customer'
          }`,
          cdpPayload,
          siteSettings?.source?.fields?.name?.value
        );
        if (
          data &&
          data?.data?.contactType?.includes(PATIENT_TYPE_ADULT) &&
          siteSettings?.source?.fields?.name?.value === 'CR'
        ) {
          dispatch(setAuthentication(true));
        }
        isCurrentResume.current = true;
        break; // Exit the loop if the retry succeeds
      } catch (e: any) {
        if (retryCount.current >= MAX_RETRY_COUNT) {
          throw new Error('savedataToCdp failed'); // Exit the loop if all retries fail
        }
      }
    }
  };

  const fetchNextSteps = async () => {
    let retryAttempts = 0;
    const extensionData = {
      enrollmentId: getEnrollmentId() as any,
    };
    while (retryAttempts < MAX_EXPERIENCE_RETRY_COUNT) {
      try {
        retryAttempts += 1;
        const experienceId = process.env.NEXT_PUBLIC_CDP_EXPERIENCE_CONTACTINFO_ID;

        const data = await fetchNextPossiblePathAndData(
          experienceId,
          false,
          false,
          sitecoreContext?.site?.name
        );
        cdpService.event(CDP_EVENTS.CONTACTFORM_EXPERIENCE_SUCCESS, extensionData);
        return data; // Exit the loop if the retry succeeds
      } catch (e: any) {
        cdpService.event(CDP_EVENTS.CONTACTFORM_EXPERIENCE_FAILURE, extensionData);
        if (retryAttempts >= MAX_EXPERIENCE_RETRY_COUNT) {
          throw new Error('fetchNextSteps failed'); // Exit the loop if all retries fail
        }

        // Wait for 2 second before retrying
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  const failureCallback = () => {
    setIsLoading(false);
    const extensionData = {
      ContactFormSubmit: false,
      tenantKey: tenantKey,
      journeyType: journeyType as string,
    };
    cdpService.event(CDP_EVENTS.CONTACT_FORM_ERROR, extensionData);
  };

  const createCdpPayload = (
    getValues: () => any,
    selectedLanguage: any,
    potentialId: number,
    autoCompleteLocationInfo: any,
    tenantKey: string,
    cdpState: any,
    selectedPlace: any,
    selectedCOIList: any,
    ipAddress: string
  ) => {
    const formData = getValues();
    const payloadData = formatCaregiverFormData(
      formData,
      selectedLanguage,
      getCookie('bx_guest_ref'),
      potentialId,
      autoCompleteLocationInfo,
      tenantKey,
      getOrCreateEnrollmentId(),
      cdpState?.patientReferralStatus,
      selectedPlace,
      ipAddress
    );
    if (!isEmpty(selectedCOIList)) {
      const communityList = getCommunities(selectedCOIList);
      const communities = getStringValFromArrayForCDP(communityList, 'value', 'key');
      (payloadData as any)['communities'] = communities;
      (payloadData as any)['conditionOfInterest'] = getRespectiveCOIList(selectedCOIList, true);
    }
    const enrollmentId = getOrCreateEnrollmentId();
    return {
      ...payloadData,
      journeyStatusRequest: {
        enrollmentId: enrollmentId,
        journeyStep: JOURNEY_STEP.CONTACT_INFO,
        journeyStatus: '',
      },
    };
  };

  const { mutate } = useStoreContactInfoData(successCallback, failureCallback);

  const saveDataToDBAndCdp = async (data: any) => {
    let token: string | null = null;
    let ipAddress = '';
    if (isEnableGoogleRecaptcha || isRecapchaEnabled) {
      if (!executeRecaptcha) {
        return false;
      }
      token = await executeRecaptcha('caregiver_form');
    }
    try {
      const { data: res } = await axios.get(`/api/getIp`);
      ipAddress = res?.ip;
    } catch (_e: any) {}

    const contactInfoData = formatCaregiverFormData(
      data,
      selectedLanguage,
      getCookie('bx_guest_ref'),
      potentialId,
      autoCompleteLocationInfo,
      tenantKey,
      getOrCreateEnrollmentId(),
      cdpState?.patientReferralStatus,
      selectedPlace,
      ipAddress
    );
    const payload = {
      ...contactInfoData,
      token,
      contactFormSettingsId: reCaptchItem?.id,
      siteSettingsId,
    };
    if (!isEmpty(selectedCOIList)) {
      const communityList = getCommunities(selectedCOIList);
      const communities = getStringValFromArray(communityList, 'value', 'key');
      (payload as any)['communities'] = communities;
      (payload as any)['conditionOfInterest'] = getRespectiveCOIList(selectedCOIList);
    }

    const cdpPayload = createCdpPayload(
      formHandlerObject.getValues,
      selectedLanguage,
      potentialId,
      autoCompleteLocationInfo,
      tenantKey,
      cdpState,
      selectedPlace,
      selectedCOIList,
      ipAddress
    );

    dispatch(updateContactInfo(payload));
    setIsLoading(true);

    // Call AddUpdatePatientDetails first
    try {
      const urlParams =
        (!isEmpty(queryParamsData) &&
          Object.entries(queryParamsData).map(([key, value]) => ({ key, value }))) ||
        [];
      if (urlParams && urlParams.length > 0) {
        (cdpPayload as any)['urlParams'] = urlParams;
      }
      await savedataToCdp(cdpPayload);
    } catch (error) {
      setIsLoading(false);
      throw new Error('AddUpdatePatientDetails failed');
    }
    mutate({ url: '/Delegate/Add', payload });
    return;
  };

  const populateExistingUserConsents = async (
    email: string,
    participantFirstName: string,
    participantLastName: string,
    participantDateOfBirthDay: string,
    participantDateOfBirthMonth: string,
    participantDateOfBirthYear: string
  ) => {
    try {
      const participantDateOfBirth = formatDOB(
        participantDateOfBirthDay,
        participantDateOfBirthMonth,
        participantDateOfBirthYear
      );

      const { data: res } = await checkReturningUser(
        false,
        tenantKey,
        PatientType.CAREGIVER,
        email,
        returningUserTimeInterval,
        returningUserTimeValue,
        participantFirstName,
        participantLastName,
        participantDateOfBirth,
        false,
        true,
        sitecoreContext?.site?.name
      );
      retuningUser.current = res;

      if (res?.secondaryconsent?.combinedConsentAccepted === 'True') {
        if (Object.keys(res?.secondaryconsent).length) {
          updateConsents('combined', res?.secondaryconsent);
        }
      } else {
        if (Object.keys(res?.primaryconsent).length) {
          updateConsents('primary', res?.primaryconsent);
        }
        if (Object.keys(res?.secondaryconsent).length) {
          updateConsents('secondary', res?.secondaryconsent);
        }
      }
    } catch (_e) {
      retuningUser.current = null;
      return;
    }
  };

  const performReturningUserCheckOnSubmit = async (
    data: any,
    isResumeEmailCheck: boolean,
    isReturnCheck: boolean,
    isJourney3: boolean
  ) => {
    try {
      const email = data['CaregiverEmail'];
      const participantFirstName = data['ParticipantFirstName'];
      const participantLastName = data['ParticipantLastName'];
      const participantDateOfBirth = formatDOB(data['Day'], data['Month'], data['Year']);
      setIsLoading(true);
      const { data: res } = await checkReturningUser(
        true,
        tenantKey,
        PatientType.CAREGIVER,
        email,
        returningUserTimeInterval,
        returningUserTimeValue,
        participantFirstName,
        participantLastName,
        participantDateOfBirth,
        isResumeEmailCheck,
        isReturnCheck,
        sitecoreContext?.site?.name,
        isJourney3
      );
      setIsLoading(false);
      retuningUser.current = res;

      if (res?.showResumeEmailModal === true && !isCurrentResume.current) {
        setEmailSentModal();
        showEmailSentModal();
      } else if (res?.isReturningUser === false) {
        if (res?.potentialParticipantId) {
          setCookie('bx_guest_ref', res?.potentialParticipantId, {
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
            secure: true,
          });
          dispatch(addpotentialParticipantId(res?.potentialParticipantId));
          updatePpidRef.current = true;
        }
        if (res?.browserId) {
          const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
          setCookie(cookieName, res?.browserId, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });
        }
        await saveDataToDBAndCdp(data);
      } else {
        if (res?.showCustomErrorMessageModel) {
          // show modal popup
          setCustomErrorModal();
          showCustomErrorModal();
        } else if (res?.showPatientExistModel) {
          // show modal popup
          setPatientExistModal();
          showPatientExistErrorModal();
        }
        return;
      }
    } catch (_e) {
      setIsLoading(false);
      return;
    }
  };

  const onSubmit = async (data: any) => {
    // check for returning user before submitting information
    retryCount.current = 0;
    let isResumeEmailCheck = false;
    let isReturnCheck = false;
    let isJourney3 = false;
    if (journeyType === JOURNEYS.JOURNEY_03) {
      isResumeEmailCheck = !siteSettings?.disableResumeJourney?.value ? true : false;
      isJourney3 = true;
    }
    if (journeyType === JOURNEYS.JOURNEY_02) {
      isResumeEmailCheck = !siteSettings?.disableResumeJourney?.value ? true : false;
      isReturnCheck = true;
    }
    if (journeyType === JOURNEYS.JOURNEY_01) {
      isResumeEmailCheck = false;
      isReturnCheck = true;
    }
    performReturningUserCheckOnSubmit(data, isResumeEmailCheck, isReturnCheck, isJourney3);
  };

  return (
    <ContactInfoContextProvider formHandlerObject={formHandlerObject}>
      <ContactInfo onSubmit={onSubmit} fields={props?.fields} isLoading={isLoading} />
    </ContactInfoContextProvider>
  );
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  //TODO: Need to check if we can have this as commom code, can be used for other contact forms
  const context = layoutData?.sitecore?.context as ExtenedLayoutServiceContextInterface;
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';
  const countryId = context?.siteSettings?.country?.id as string;

  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }

  const stateList = await getAllStates(language, countryId);
  const timeZoneList = await getAllTimeZones(language, countryId);
  const COIList = await fetchConditionOfInterest(language);

  return {
    stateList,
    timeZoneList,
    COIList,
  };
};
export default CaregiverContactInfo;
