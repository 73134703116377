import React, { useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Field, ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { StCarouselWrapper } from './CarousalList.style';
import { getCarouselConfig } from '../../lib/utils/common';
import Slide from './Slide/Slide';
import { CAROUSEL_VARIATION } from '../../constants';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

interface ICtaButton {
  ariaLabel: Field<string>;
  buttonVariant: Field<string>;
  link: Field;
}
interface ICtaButtons {
  fields: ICtaButton;
}
export interface ICarousalPanelFields {
  ariaLabel: Field<string>;
  VideoUrl: Field<string>;
  background: Field<string>;
  ctaButtons: ICtaButtons[];
  description: Field<string>;
  title: Field<string>;
  type: Field<string>;
  backgroundDesktop: ImageField;
  backgroundMobile: ImageField;
  backgroundTablet: ImageField;
}
interface ICarousalPanel {
  fields: ICarousalPanelFields;
}
interface IFields {
  carousalVariationType: Field<string>;
  autoRotate: Field<boolean>;
  autoRotateDuration: Field<string>;
  playIcon: Field<string>;
  leftArrowImage: Field<string>;
  rightArrowImage: Field<string>;
  carousalPanel: ICarousalPanel[];
  ariaLabel: Field<string>;
  playIconAriaLabel: Field<string>;
}

interface CarouselListProps {
  fields?: IFields;
}

const CarouselList: React.FC<CarouselListProps> = (props) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const currentVideoIndex = useRef<number | null>(null);
  const theme = useTheme();
  if (!props?.fields) return <></>;
  const carousalPanel = props?.fields?.carousalPanel;
  const variation = props?.fields?.carousalVariationType?.value || CAROUSEL_VARIATION.VARIATION1;
  const autoRotateDuration = props?.fields?.autoRotateDuration?.value || '400';
  const playIcon = props?.fields?.playIcon;
  const playIconAriaLabel = props?.fields?.playIconAriaLabel;

  const leftArrowImage = props?.fields?.leftArrowImage;
  const rightArrowImage = props?.fields?.rightArrowImage;
  let autoPlay = props?.fields?.autoRotate?.value || false;
  if (isEditorActive()) {
    autoPlay = false;
  }
  const CarouselConfig = getCarouselConfig(variation, autoRotateDuration, playing);
  const handlePlay = (index: number) => {
    currentVideoIndex.current = index;
    setPlaying(true);
  };

  const handlePause = () => {
    currentVideoIndex.current = null;

    setPlaying(false);
  };

  const handleOnChange = () => {
    if (playing) {
      setPlaying(false);
    }
  };
  return (
    <StCarouselWrapper id="hero-banner">
      <Carousel
        autoPlay={playing ? false : autoPlay}
        height={'656px'}
        animation="slide" // Specify the animation type (e.g., "slide", "fade")
        NextIcon={<NextImage height={40} width={40} field={rightArrowImage} />}
        PrevIcon={<NextImage height={40} width={40} field={leftArrowImage} />}
        onChange={handleOnChange}
        indicatorIconButtonProps={{
          style: {
            color: theme.palette.backgroundType2.main,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: theme.palette.neutral0.main,
          },
        }}
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        {...CarouselConfig}
      >
        {carousalPanel?.map((item, index) => {
          const type = item?.fields?.type?.value;
          const background = item?.fields?.background;
          const ariaLabel = item?.fields?.ariaLabel;

          return (
            <Grid container key={index}>
              <Slide
                item={item}
                type={type}
                background={background}
                index={index}
                setPlaying={setPlaying}
                playing={playing}
                playIcon={playIcon}
                variation={variation}
                handlePlay={handlePlay}
                handlePause={handlePause}
                currentVideoIndex={currentVideoIndex}
                ariaLabel={ariaLabel}
                playIconAriaLabel={playIconAriaLabel}
              />
            </Grid>
          );
        })}
      </Carousel>
    </StCarouselWrapper>
  );
};

export default CarouselList;
